import { Autocomplete, IconButton,TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { viewUOMListAPICall, viewUOMSubListAPICall } from "../../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import HSNC from "../../../../../../Assets/JSON/gstHsnCodes.json"
import { useSelector } from "react-redux";
import { getAllTaxSettingsListAPICall } from "../../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import { attributeListAPICall } from "../../../Configuration/Attribute/AttributeAPI";
import { filterObjFromList, findObjFromList } from "../../../../../../Js/generalFunctions";
import ErrorSnackbar from "../../../../../Single Components/SnackBars/ErrorSnackbar";
import { today } from "../../../../../../Js/Date";


export const ProductsSwitchInputField = (props) => {

    const { options, editedData, editEnabled,productAdded } = props

    const allBrandList = useSelector((state) => state.readymadeBrandSlice.value);
    const uomSubList = useSelector((state) => state.unitOfMeasurmentSlice.subUomList);
    const taxListFull = useSelector((state) => state.taxSettingsSlice.value);

    const attributeList = useSelector((state) => state?.attributeSlice?.value);
    const trackingList = ["No Tracking", "By Lots", "By Unique Serial No"]
    const hsnFiltered = [...new Set(HSNC.map(item => item.hsn))]

    const [hsCodeFiltered, setHsCodeFiltered] = useState([])
    const [rowIndex, setRowIndex] = useState(-1);

    const formValuesInitialState = {
        barcode: "",
        brand: null,
        uom: null,
        purchaseUom: null,
        cost: 0,
        salesPrice: 0,
        hsCode: null,
        hsnCode: null,
        releaseDate: today,

        nonTaxable:false,
        vendorTax: [],
        customerTax: [],

        tracking: null,
        reorderQty: "",



        attribute: null
    }
    const [formModified, setFormModified] = useState(false)
    const [formValues, setFormValues] = useState(formValuesInitialState)
    const [validationAlert, setValidationAlert] = useState({})

    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [image4, setImage4] = useState(null);

    const [attributeValueArray, setAttributeValueArray] = useState([])


    const [attributeTable, setAttributeTable] = useState([])
    const [snackBarStates, setSnackBarStates] = useState({
        success: false,
        error: false,
        message: "",
        alert: false
    });

    const closeSnackbar = () => {
        setSnackBarStates({
            ...snackBarStates,
            success: false,
            error: false,
            alert: false,
            message: "",
        });
    };

    const getFormInfo = (key) => (e, newValue) => {
        const { value } = e.target;
        setValidationAlert({});
        if (key === "uom") {
            setFormValues({ ...formValues, uom: newValue, purchaseUom: null });
        }
        else if (key === "hsnCode") {
            setFormValues({ ...formValues, hsnCode: newValue, hsCode: null });
        }

        else {
            setFormValues({ ...formValues, [key]: newValue === undefined ? value : newValue });
        }
        setFormModified(!formModified)
    };

    const addAttributeTable = (key) => () => {

        let clearAttributes = () => {
            setAttributeValueArray([])
            setFormValues({ ...formValues, attribute: null })
            setRowIndex(-1)
        }

        if (formValues?.attribute === null) {
            setValidationAlert({ ...validationAlert, attribute: "select this field" })
        }
        else if (attributeValueArray.length === 0) {
            setValidationAlert({ ...validationAlert, attributeValue: "select this field" })
        }
        else {

            const attributeId = formValues?.attribute?._id;

            // Check if the attributeId already exists in the attributeTable
            const isAttributeIdExist = attributeTable.some(item => item.attributeId === attributeId);


            if(isAttributeIdExist){
                setSnackBarStates({ ...snackBarStates, message: "attribute type already  exist!!", error: true })
            }
            else{
                setAttributeTable((prevTable) => [...prevTable, {
                    attribute: formValues?.attribute?.attributeName,
                    values: attributeValueArray,
                    attributeId: formValues?.attribute?._id
                }])
                clearAttributes()
                setFormModified(!formModified)
            }
        }
    }

    const deleteTableData = () => {
        setAttributeValueArray([])
        setFormValues({ ...formValues, attribute: null })
        setRowIndex(-1)
    }
    const handleClickRow = (data, index) => {
        setRowIndex(index);
        setFormValues({
            ...formValues,
            attribute: filterObjFromList("attributeName", attributeList, "attribute", data)
        });

        setAttributeValueArray(data?.values || []);

        const updatedTable = [
            ...attributeTable.slice(0, index),
            ...attributeTable.slice(index + 1),
        ];
        setAttributeTable(updatedTable);
    };


    useEffect(() => {
        viewUOMListAPICall()
        getAllTaxSettingsListAPICall();
        attributeListAPICall()
        viewUOMSubListAPICall()
    }, [])

    useEffect(() => {
        let filteredArray = []
        filteredArray = HSNC.filter((obj) =>
            obj.hsn === formValues?.hsnCode
        )
        setHsCodeFiltered(filteredArray)
    }, [formValues?.hsnCode])


    useEffect(() => {
        props.onData({
            formValues: formValues,
            attributeTable: attributeTable?.map((item) => {
                return {
                    attribute: item?.attribute,
                    values: item?.values?.map((r, i) => (r?.value)),
                    _id:item?.attributeId || item?._id
                }
            }),
            image1: image1,
            image2: image2,
            image3: image3,
            image4: image4
        });
    }, [formModified])

    useEffect(() => {
          if(editedData !== undefined && formValues?.hsCode === null){
              setFormValues({ ...formValues, hsCode: filterObjFromList("hs", hsCodeFiltered, "hsCode", editedData) })
              setFormModified(!formModified)
            }
    }, [hsCodeFiltered])

    useEffect(() => {
        if (editedData !== undefined) {
            setFormValues({
                ...formValues,
                isEdit: true,
                barcode: editedData?.barcode || "",
                brand: findObjFromList("brandName", allBrandList, "brand", editedData),
                uom: findObjFromList("_id", uomSubList, "uom", editedData),
                purchaseUom:findObjFromList("_id", uomSubList, "purchaseuom", editedData),
                cost: editedData?.cost  || 0,
                salesPrice: editedData?.salesPrice || 0,
                hsnCode: editedData?.hsnCode || null,
                releaseDate:typeof editedData.releaseDate === 'number'?editedData?.RELEASEDATE: editedData?.releaseDate,

                nonTaxable:editedData?.nonTaxable,
                vendorTax:taxListFull.filter(obj => editedData?.vendorTax?.includes(obj._id)),
                customerTax:taxListFull.filter(obj => editedData?.customerTax?.includes(obj._id)),
                tracking: editedData?.tracking || null,
                reorderQty: editedData?.reorderQty || "",

            })
            setAttributeTable(editedData?.attribute || [])
            setFormModified(!formModified)
        }
        // attribute table
    }, [
        editedData,
        allBrandList,
        uomSubList,
        taxListFull
    ])

    useEffect(()=>{
        if(productAdded){

        
            setFormValues(formValuesInitialState)
            setImage1(null)
            setImage2(null)
            setImage3(null)
            setImage4(null)
            setAttributeTable([])
        }

    },[productAdded])

    useEffect(() => {
      const handleBeforeUnload = (event) => {
        const shouldShowAlert = !editEnabled;
        if (shouldShowAlert) {
          event.preventDefault();
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [editEnabled]);
    return (

        <div className="global-input-field-container products-info-container">
            {
                options?.option === "General Info" ?
                    <>
                        <div className="global-single-input auto-complete">
                            <p>Barcode</p>
                            <input type="number"
                                value={formValues?.barcode}
                                onChange={getFormInfo("barcode")}
                                disabled={editEnabled}
                            />
                        </div>
                        {/* <div className="global-single-input auto-complete">
                            <p>Brand</p>
                            <Autocomplete
                                options={allBrandList || []}
                                getOptionLabel={(option) => option?.brandName}
                                renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                      <span>{option.brandName}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.brand}
                                onChange={getFormInfo("brand")}
                                disabled={editEnabled}

                            />
                        </div> */}
                        <div className="global-single-input auto-complete">
                            <p>UOM</p>
                            <Autocomplete
                                options={uomSubList || []}
                                getOptionLabel={(option) => option?.uomName}
                                renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                      <span>{option.uomName}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.uom}
                                onChange={getFormInfo("uom")}
                                disabled={editEnabled}

                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Purchase UOM</p>
                            <Autocomplete
                                options={uomSubList?.filter((obj)=>obj?.parentCatId===(formValues?.uom?.parentCatId)) || []}
                                getOptionLabel={(option) => option?.uomName}
                                renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                      <span>{option.uomName}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.purchaseUom}
                                onChange={getFormInfo("purchaseUom")}
                                disabled={editEnabled}

                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Cost</p>
                            <input type="number"
                                value={formValues?.cost}
                                onChange={getFormInfo("cost")}
                                disabled={editEnabled}
                                onFocus={(e)=>e.target.select()}
                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Sales Price</p>
                            <input type="number"
                                value={formValues?.salesPrice}
                                onChange={getFormInfo("salesPrice")}
                                disabled={editEnabled}
                                onFocus={(e)=>e.target.select()}
                            />
                        </div>

                        <div className="global-single-input auto-complete">
                            <p>HSN Code</p>
                            <Autocomplete
                                options={hsnFiltered || []}
                                getOptionLabel={(option) => option}
                                renderOption={(props, option) => (
                                    <li {...props} key={option}>
                                      <span>{option}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.hsnCode}
                                onChange={getFormInfo("hsnCode")}
                                disabled={editEnabled}

                            />
                        </div>

                        <div className="global-single-input auto-complete">
                            <p>HS Code</p>
                            <Autocomplete
                                options={hsCodeFiltered || []}
                                getOptionLabel={(option) => option?.hs}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.hs}>
                                      <span>{option?.hs}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.hsCode}
                                onChange={getFormInfo("hsCode")}
                                disabled={editEnabled}

                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Release Date</p>
                            <input type="date"
                                value={formValues?.releaseDate}
                                onChange={getFormInfo("releaseDate")}
                                disabled={editEnabled}
                            />
                        </div>
                    </>
                    : options?.option === "Accounting" ?
                      <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                          <div>
                             <input 
                               type="checkbox" 
                               id="productMasterTaxable" 
                               style={{accentColor:"#000",cursor:"pointer"}}
                               value={formValues?.nonTaxable}
                               onChange={(e)=>
                               { setFormValues({...formValues,nonTaxable:e.target.checked})
                                 setFormModified(!formModified)
                            }
                            }
                                checked={formValues?.nonTaxable===true}
                               />
                            <label htmlFor="productMasterTaxable" style={{fontSize:"0.8rem",fontWeight:"600"}}>Non Taxable</label>
                           </div>
                           {
                            formValues?.nonTaxable !== true &&
                               <div style={{display:"flex",justifyContent:"space-between"}}>
                            
                            <div className="global-single-input auto-complete">
                                <p>Vendor Tax</p>
                                <Autocomplete
                                    multiple
                                    options={taxListFull?.filter(obj=>obj?.taxType === "Purchase")?.filter(option => !formValues?.vendorTax?.length || option?.includedPrice === formValues?.vendorTax[0]?.includedPrice)  || []}
                                    getOptionLabel={(option) => option?.taxName}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option?._id}>
                                          <span>{ option?.taxName}</span>
                                        </li>
                                      )}
                                    renderInput={(params) => (<TextField
                                        {...params}
                                    />)}
                                    value={formValues?.vendorTax}
                                    onChange={getFormInfo("vendorTax")}
                                    disabled={editEnabled}

                                />
                            </div>
                            <div className="global-single-input auto-complete">
                                <p>Customer Tax</p>
                                <Autocomplete
                                    multiple
                                    options={taxListFull?.filter(obj=>obj?.taxType === "Sales")?.filter(option => !formValues?.customerTax?.length || option?.includedPrice === formValues?.customerTax[0]?.includedPrice) || []}
                                    getOptionLabel={(option) => option?.taxName}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option?._id}>
                                          <span>{ option?.taxName}</span>
                                        </li>
                                      )}
                                    renderInput={(params) => (<TextField
                                        {...params}
                                    />)}
                                    value={formValues?.customerTax}
                                    onChange={getFormInfo("customerTax")}
                                    disabled={editEnabled}

                                />
                            </div>
                               </div>
                           }
                          
                          </div>
                        : options?.option === "Inventory" ?
                            <>
                                <div className="global-single-input auto-complete">
                                    <p>Tracking</p>
                                    <Autocomplete
                                        options={trackingList}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (<TextField
                                            {...params}
                                        />)}
                                        value={formValues?.tracking}
                                        onChange={getFormInfo("tracking")}
                                        disabled={editEnabled}

                                    />
                                </div>
                                <div className="global-single-input auto-complete">
                                    <p>Reorder Quantity</p>
                                    <input type="number"
                                        value={formValues?.reorderQty}
                                        onChange={getFormInfo("reorderQty")}
                                        disabled={editEnabled}
                                    />
                                </div>
                            </>
                            : options?.option === "Attribute" ?

                                    <div style={{ width: "100%" }}>
                                        <div style={{ display: "flex", padding: "1%" }}>
                                            <div className="global-single-input auto-complete" style={{ width: "18%" }}>
                                                <p>Attribute</p>
                                                <Autocomplete
                                                    options={attributeList || []}
                                                    getOptionLabel={(option) => option?.attributeName}
                                                    renderInput={(params) => (<TextField
                                                        {...params}
                                                    />)}
                                                    value={formValues?.attribute}
                                                    onChange={getFormInfo("attribute")}
                                                    disabled={editEnabled}

                                                />
                                                <p className="doc-validation-alert">{validationAlert?.attribute}</p>
                                            </div>
                                            <div className="global-single-input auto-complete attribute-multiple-auto-complete" style={{ width: "auto", minWidth: "150px",maxWidth:"300px" }}>
                                                <p>Values</p>
                                                <Autocomplete
                                                    options={formValues?.attribute?.attCatogory || []}
                                                    getOptionLabel={(option) => option?.value}
                                                    renderInput={(params) => (<TextField
                                                        {...params}
                                                    />)}
                                                    multiple
                                                    onChange={(e, newValue) => {
                                                        setAttributeValueArray(newValue)
                                                        setValidationAlert({})
                                                    }}
                                                    value={attributeValueArray}
                                                    disabled={editEnabled}

                                                />
                                                <p className="doc-validation-alert">{validationAlert?.attributeValue}</p>
                                            </div>
                                            {rowIndex !== -1 ? (
                                                <IconButton
                                                    onClick={addAttributeTable("edit")}
                                                    style={{ alignSelf: "flex-end" }}
                                                >
                                                    <i class="bi bi-pencil-square edit-icon1"></i>
                                                </IconButton>
                                            ) : (
                                                <button
                                                    onClick={addAttributeTable("add")}
                                                    style={{ alignSelf: "flex-end" }}
                                                    className="plus-btn"
                                                    disabled={editEnabled}
                                                >
                                                    +
                                                </button>
                                            )}
                                            {rowIndex !== -1 && (
                                                <IconButton
                                                    onClick={deleteTableData}
                                                    style={{ alignSelf: "flex-end" }}
                                                >
                                                    <i
                                                        className="bi bi-trash3"
                                                        style={{ color: "#db0000" }}
                                                    ></i>
                                                </IconButton>
                                            )}
                                        </div>
                                        <div className="global-table-container">
                                            <table className="global-table">
                                                <thead>
                                                    <tr>
                                                        <th>Attribute</th>
                                                        <th>Values</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        attributeTable.length !== 0 ?
                                                            attributeTable?.map((r, i) => (
                                                                <tr
                                                                    key={i}
                                                                    onClick={() =>editEnabled===false && handleClickRow(r, i)}
                                                                >
                                                                    <td>{r?.attribute}</td>
                                                                    {/* {
                                                                        editedData===undefined? */}
                                                                            {/* <td className='table-data-box' style={{ textAlign: "center", padding: "3px" }}>
                                                                                {
                                                                                    r?.values?.map((r, i) => (
                                                                                        <div key={i}>{r}</div>
                                                                                    ))
                                                                                }
                                                                            </td> : */}
                                                                            <td className='table-data-box' style={{ textAlign: "center", padding: "3px" }}>
                                                                               <div className="table-data-round-parent">

                                                                                {
                                                                                    r?.values?.map((r, i) => (
                                                                                        <div className='round-border' key={i}>{r?.value}</div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                    {/* } */}
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr>
                                                                <td colSpan={2}>No Attribute added</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", gap: "15px" }}>
                                    </div>
            }


            <ErrorSnackbar
                style={{ top: "8%", left: "34%", height: "20px", width: "auto" }}
                open={snackBarStates.alert}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <ErrorSnackbar
                open={snackBarStates.error}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
        </div>
    )
}