import React, { useState, useEffect } from "react";
import { Popover, Button, List, Typography, Badge } from "antd";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import io from "socket.io-client";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { completedSelfOrderLists } from "../../SelfOrderComponents/Components/Order/selfOrderListingAPI";
import { setOrderIdInPOS } from "../Modules/Sales/Billing/Slice/billingScreenSlice";
import { setBtnSelected } from "../Modules/Sales/Billing/Components/topBarSliceBilling";
import { getInvoiceReceipt } from "../Modules/Sales/Billing/API/billingPaymentSection";

const NotificationPopup = () => {

    const dispatch=useDispatch()

    const path = window.location.pathname
    // const { branchPk, data } = useSelector((state) => state?.loginResponseSlice?.value);
    const { branchPk, data } = useSelector((state) => state?.loginResponseSlice?.value ?? {});
    const { completedOrders } = useSelector((state) => state?.selfOrderMainSlice);
    const [popoverVisible, setPopoverVisible] = useState(false);

    useEffect(() => {
        if (path === "/userdashboard/pos/billingnew") {
            const socket = io(axios.defaults.baseURL, {
                path: "/socket.io/",
                reconnection: true,
                transports: ["websocket"],
                reconnectionAttempts: 5
            });

            socket.on("connect", () => {
                if (data && branchPk) {
                    socket.emit("setUsername", data?._id, branchPk);
                }
            });
            socket.on("selfordercompleted", () => {
                completedSelfOrderLists({branchId:branchPk},openPopup)
            });

            return () => {
                // Clean up event listeners
                socket.off("connect");
                socket.off("selfordercompleted");
                socket.disconnect();
            };
        }
    }, [path]);

    // Function to open the popup
    const openPopup = () => {
        setPopoverVisible(true);
    };

    const handlePay = (orderId) => {
        dispatch(setOrderIdInPOS(orderId))
        dispatch(setBtnSelected("payment"))
        setPopoverVisible(false);
    };

    const handlePrint = (orderId) => {
        dispatch(setBtnSelected(null))
        getInvoiceReceipt({orderId})
    };

    const handleNotificationClick = () => {
        if (path === "/userdashboard/pos/billingnew") {
            setPopoverVisible(!popoverVisible) // Toggle visibility on icon click
            !popoverVisible && completedSelfOrderLists({branchId:branchPk})
                
        } 

    };

    const notificationContent = (
        <List
            dataSource={completedOrders}
            renderItem={(item) => (
                <List.Item
                    style={{
                        padding: "8px 12px",
                        borderBottom: "1px solid #f0f0f0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    {/* Order Details */}
                    <div style={{ flex: 1, marginRight: "30px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {item.name || "BILL/#"}
                        </span>
                        <span style={{ fontSize: "14px", marginLeft: "8px", color: "#666" }}>
                            | {item?.ordType==="TAKEAWAY"?"Takeaway": `Table:${item.tableNames?.join(",") || "-NIL-"}`} 
                        </span>
                        <br />
                        <span style={{ fontSize: "12px", color: "#666" }}>
                            Customer: {item.customerName || "Munshid Pn"}
                        </span>
                    </div>

                    {/* Action Icons */}
                    <div style={{ display: "flex", gap: "10px" }}>
                        <PrintOutlinedIcon
                            onClick={() => handlePrint(item._id)}
                            style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                color: "#000",
                            }}
                        />
                        <PaidOutlinedIcon
                            onClick={() => handlePay(item._id)}
                            style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                color: "#1890ff",
                            }}
                        />
                    </div>
                </List.Item>
            )}
        />
    );

    const customTitle = (
        <div style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", borderBottom: "2px dashed #c5bbbb" }}>
            SELF ORDERS
        </div>
    );
    return (
        <Popover
            content={notificationContent}
            title={customTitle}
            trigger="click"
            open={popoverVisible} // Use `open` to control visibility
            onOpenChange={(visible) => setPopoverVisible(visible)} // Close on outside click
        >
            <Badge
                count={completedOrders.length}
                overflowCount={99} // Limit the count display
                offset={[-9, 9]} // Adjust position of the count
                style={{
                    backgroundColor: '#f56c6c',
                    color: 'white',
                    fontSize: '10px', // Smaller font size
                    height: '16px', // Adjust height for smaller size
                    minWidth: '16px', // Adjust width for circular appearance
                    lineHeight: '16px', // Center align the number
                    boxShadow: '0 0 0 1px white inset',
                    borderRadius: '50%', // Make it perfectly round
                }}
            >
                <Button
                    shape="circle"
                    icon={<NotificationsNoneOutlinedIcon />}
                    style={{
                        background: "inherit",
                        color: "black",
                        border: "none"
                    }}
                    onClick={handleNotificationClick} // Open manually by clicking
                />
            </Badge>
        </Popover>
    );
};

export default NotificationPopup;
