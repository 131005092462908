import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBranchPosConfigurations } from './BranchSettingsSlice'

const PosBranchConfigurations = () => {

    const dispatch=useDispatch()
    const decimalPosition=localStorage.getItem("decimalPosition")
    const branchSettingsView = useSelector((state) => state.branchSettingsSliceNew.rowData);
    const CategoryList = useSelector((state) => state.categorySlice.value);

    //Active Employee List
    const employeeList = useSelector((state) => state.allActiveEmployeeSlice.value);

    const [formValues, setFormValues] = useState({
        enableKot: false,
        cafeteria: false,
        recipeAuto: false,
        updateOrdAuth: false,
        cancelOrdAuth: false,
        addDiscountAuth: false,
        enableMultipleOrder: false,
        deliveryCharge: false,
        deliveryAmt: "",
        packageCharge: false,
        packageAmt: "",
        printCount: null,
        employees:[],
        categories:[]
    })
    const [validationAlert, setValidationAlert] = useState({})


    const getFormInfo = (key) => (e, newValue) => {
        setFormValues({ ...formValues, [key]: newValue !== undefined ? newValue : e.target.value })
    }
    const checkboxChange = (key) => (e) => {
        setFormValues({ ...formValues, [key]: e.target.checked })
    }
    const roundValues=()=>{
        setFormValues({...formValues,deliveryAmt:Number(formValues.deliveryAmt)?.toFixed(decimalPosition),packageAmt:Number(formValues.packageAmt)?.toFixed(decimalPosition)})
      }
    useEffect(()=>{
        dispatch(setBranchPosConfigurations(formValues))
    },[formValues])

    useEffect(()=>{
        if(branchSettingsView){
            setFormValues({...formValues,
                enableKot:branchSettingsView?.enableKot,
                cafeteria:branchSettingsView?.isCafeteria,
                recipeAuto:branchSettingsView?.recipeAuto,
                updateOrdAuth: branchSettingsView?.updateOrdAuth,
                cancelOrdAuth: branchSettingsView?.cancelOrdAuth,
                addDiscountAuth: branchSettingsView?.addDiscountAuth,
                enableMultipleOrder: branchSettingsView?.enableMultipleOrder,
                deliveryCharge: branchSettingsView?.deliveryCharge,
                deliveryAmt: Number(branchSettingsView?.deliveryAmt)?.toFixed(decimalPosition),
                packageCharge: branchSettingsView?.packagingCharge,
                packageAmt: Number(branchSettingsView?.pkgCharge)?.toFixed(decimalPosition),
                printCount: branchSettingsView?.printCount || 1,
                employees: branchSettingsView?.cafeEmpId?.map((emp)=>({_id:emp?.empId,staff_name:emp?.staff_name})),
                categories: branchSettingsView?.cafeCatId?.map((cat)=>({_id:cat?.catId,categoryName:cat?.categoryName}))
            })
        }
    },[branchSettingsView])
    return (
        <div className='new-global-white-bg-container'>
            <h4>POS Configurations</h4>
            <div className='pos-settings-checkbox-container'>
                <input
                    type="checkbox"
                    id="enableKot"
                    value={formValues?.enableKot}
                    onChange={checkboxChange("enableKot")}
                    checked={formValues?.enableKot}
                />
                <label htmlFor="enableKot">KOT</label>
            </div>

            <div className='pos-settings-checkbox-container'>
                <input
                    type="checkbox"
                    id="cafeteria"
                    value={formValues?.cafeteria}
                    onChange={checkboxChange("cafeteria")}
                    checked={formValues?.cafeteria === true}
                />
                <label htmlFor="cafeteria">Cafeteria</label>
            </div>
            {
                formValues?.cafeteria &&
                <div className='pos-settings-dropdown-container auto-complete-new' style={{justifyContent:"space-between",alignItems:"start"}}>
                        <div className="global-single-input" style={{width:"45%"}}>
                            <p>Employee</p>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                options={employeeList || []}
                                getOptionLabel={(option) => option?.staff_name}
                                renderInput={(params) => (<TextField
                                    {...params}
                                    />)}
                                    value={formValues?.employees}
                                    onChange={getFormInfo("employees")}
                                    />
                        </div>
                        <div className="global-single-input" style={{width:"45%"}}>
                            <p>Category</p>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={CategoryList || []}
                            getOptionLabel={(option) => option?.categoryName}
                            renderInput={(params) => (<TextField
                                {...params}
                            />)}
                            value={formValues?.categories}
                            onChange={getFormInfo("categories")}
                        />
                        </div>
                </div>
            }

            <div className='pos-settings-checkbox-container'>
                <input
                    type="checkbox"
                    id="recipeAuto"
                    value={formValues?.recipeAuto}
                    onChange={checkboxChange("recipeAuto")}
                    checked={formValues?.recipeAuto === true}
                />
                <label htmlFor="recipeAuto">Recipe auto preperation</label>
            </div>
            <div className='pos-settings-checkbox-container'>
                <input
                    type="checkbox"
                    id="updateOrdAuth"
                    value={formValues?.updateOrdAuth}
                    onChange={checkboxChange("updateOrdAuth")}
                    checked={formValues?.updateOrdAuth}
                />
                <label htmlFor="updateOrdAuth">Order Edit Authentication</label>
            </div>
            <div className='pos-settings-checkbox-container'>
                <input
                    type="checkbox"
                    id="cancelOrdAuth"
                    value={formValues?.cancelOrdAuth}
                    onChange={checkboxChange("cancelOrdAuth")}
                    checked={formValues?.cancelOrdAuth}
                />
                <label htmlFor="cancelOrdAuth">Order Cancel Authentication</label>
            </div>
            <div className='pos-settings-checkbox-container'>
                <input
                    type="checkbox"
                    id="addDiscountAuth"
                    value={formValues?.addDiscountAuth}
                    onChange={checkboxChange("addDiscountAuth")}
                    checked={formValues?.addDiscountAuth}
                />
                <label htmlFor="addDiscountAuth">Discount Authentication</label>
            </div>
            <div className='pos-settings-checkbox-container'>
                <input
                    type="checkbox"
                    id="enableMultipleOrder"
                    value={formValues?.enableMultipleOrder}
                    onChange={checkboxChange("enableMultipleOrder")}
                    checked={formValues?.enableMultipleOrder}
                />
                <label htmlFor="enableMultipleOrder">Multiple Order Selection In Single Table</label>
            </div>


            <div className='pos-settings-checkbox-container'>
                <input
                    type="checkbox"
                    id="deliveryCharge"
                    value={formValues?.deliveryCharge}
                    onChange={checkboxChange("deliveryCharge")}
                    checked={formValues?.deliveryCharge === true}
                />
                <label htmlFor="deliveryCharge">Delivery Charge</label>
            </div>
            {
                formValues?.deliveryCharge &&
                <div className='pos-settings-dropdown-container auto-complete-new'>
                    <p>Delivery Amount </p>
                    <TextField
                        value={formValues?.deliveryAmt}
                        onChange={getFormInfo("deliveryAmt")}
                        fullWidth
                        sx={{ input: { padding: "4px" } }}
                        onFocus={(e) => e.target.select()}
                        style={{ width: "100px" }}
                        onBlur={roundValues}

                    />
                    <p className='doc-validation-alert'>{validationAlert?.deliveryAmt}</p>
                </div>
            }
            <div className='pos-settings-checkbox-container'>
                <input
                    type="checkbox"
                    id="packageCharge"
                    value={formValues?.packageCharge}
                    onChange={checkboxChange("packageCharge")}
                    checked={formValues?.packageCharge}

                />
                <label htmlFor="packageCharge">Packaging Charge</label>
            </div>
            {
                formValues?.packageCharge &&
                <div className='pos-settings-dropdown-container auto-complete-new'>
                    <p>Package Amount </p>
                    <TextField
                        value={formValues?.packageAmt}
                        onChange={getFormInfo("packageAmt")}
                        fullWidth
                        sx={{ input: { padding: "4px" } }}
                        onFocus={(e) => e.target.select()}
                        style={{ width: "100px" }}
                        onBlur={roundValues}
                    />
                    <p className='doc-validation-alert'>{validationAlert?.packageAmt}</p>
                </div>
            }
            <div className="pos-settings-dropdown-container auto-complete-new">
                <p>Delivery Print Count</p>
                <Autocomplete
                    options={[1, 2, 3, 4, 5, 6, 7]}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (<TextField {...params} />)}
                    value={formValues?.printCount}
                    onChange={getFormInfo("printCount")}
                    style={{ width: "100px" }}
                />
            </div>

        </div>
    )
}

export default PosBranchConfigurations