import { createSlice } from "@reduxjs/toolkit";

export const loginResponseSlice=createSlice({
    name:'loginResponseSlice',
    initialState:{
        // value:undefined
        value: {} // Initialize as an empty object
    },
    reducers:{
        update_login_response:(state,action)=>{
            state.value=action.payload.loginResponse
        }
    }
})

export const {update_login_response}=loginResponseSlice.actions
export default loginResponseSlice.reducer