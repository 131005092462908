import axios from "axios";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { getPriceList, listPriceList,singleView } from "./priceListSlice";

// get currency
export const savePriceListApi = async (body, setSnackMsg,setSuccessSnackOpen,setErrorSnackOpen) => {
  try {
    let response = await axios.post(`sales/addPriceList`, body, HEADERS);
    if (response.status === 200) {
      store.dispatch(getPriceList(response.data));
      setSuccessSnackOpen(true)
      setSnackMsg("price list added!!");
    }
  } catch (error) {
    setErrorSnackOpen(true)
    setSnackMsg("something went wrong");
    console.log("Error: ", error);
    throw new Error(error);
  }
};
// get price list 
export const getPriceListApi = async () => {
  try {
    let response = await axios.post(`sales/viewPriceList`, {}, HEADERS);
    if (response.status === 200) {
      store.dispatch(listPriceList(response.data));
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};
// get price list  single view
export const singleViewPriceListApi = async (body) => {
  try {
    let response = await axios.post(`sales/priceListSingleView`, body, HEADERS);
    if (response.status === 200) {
      store.dispatch(singleView(response.data));

    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
}; 
