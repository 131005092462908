import React, { useEffect, useState } from "react";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../../Single Components/CustomPagination";
import {
  TaxSettingsFilterAPICall,
  UpdateTaxStatusAPiCall,
  createTaxSettingsApicall,
  updateTaxSettingsApiCall,
} from "../../../../API/Settings/TaxSettings/taxSettingsAPI";
import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { chartOfAccountListApiCall } from "../../../../API/AccountUpdated/chartOfAccountAPI";
import store from "../../../../Redux/store";
import { Tax_single_View } from "../../../../Redux/Settings/TaxSettings/taxSettingsSlice";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import {
  setErrorSnack,
  setSnackMsg,
} from "../../Sales/Billing/Slice/billingScreenSlice";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import {
  companyProfileInfoForUser,
  editCompanyProfileInfoAPICall,
} from "../../../../API/Settings/Company Settings/companySettingsAPI";
export const TaxSettings = () => {
  const dispatch = useDispatch();
  const companyProfile = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  console.log(companyProfile?.enableHsnCode, "......companyProfile");
  const TaxList = useSelector((state) => state.taxSettingsSlice.taxFiltered);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = TaxList?.pages;
  const [CreateTax, setCreateTax] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Percentage of price");
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  const typeNoneTax = TaxList?.list?.filter((obj) => obj?.taxType === "None");
  const SingleViewTax = useSelector(
    (state) => state?.taxSettingsSlice?.TaxSingleView
  );
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });

  const [addCreateTax, setAddCreateTax] = useState({
    taxName: "",
    taxScope: null,
    taxType: null,
    includedPrice: false,
    taxAccount: null,
    amount: "",
  });
  const [TaxStatus, setTaxStatus] = useState(false);

  const TaxScop = [
    {
      Scope: "Service",
    },
    {
      Scope: "Goods",
    },
  ];

  const Tax_type = [
    {
      Type: "Purchase",
    },
    {
      Type: "Sales",
    },
    {
      Type: "None",
    },
  ];

  const tableDataInitial = {
    tax: null,
    amount: "",
  };
  const [tableData, setTableData] = useState([tableDataInitial]);
  const [disabledInput, setDisabledInput] = useState(false);
  const [ConfEditShow, setConfEditShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [validationAlert, setValidationAlert] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

  const handleButtonClick = (e) => {
    setSelectedButton(e.target.value);
  };
  const [selectedBranch, setSelectedBranch] = useState(null);
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const CreateTaxbtn = () => {
    setCreateTax(true);
    setShowSaveBtn(true);
    setAddCreateTax({
      taxName: "",
      taxScope: null,
      taxType: null,
      includedPrice: false,
      taxAccount: null,
      amount: "",
    });
    store.dispatch(Tax_single_View({ singleViewOfTax: null }));
    setTableData([tableDataInitial]);
    setSelectedButton("Percentage of price");
    setDisabledInput(false);
  };

  const singleViewTax = (r) => {
    store.dispatch(setFilterActive(window.location.pathname));
    store.dispatch(Tax_single_View({ singleViewOfTax: r }));
    setShowSaveBtn(false);
    setCreateTax(true);
    setDisabledInput(true);
    setConfEditShow(false);
  };
  const TaxEdit = () => {
    setDisabledInput(false);
    setConfEditShow(true);
  };

  const TaxUpdate = (id, status) => {
    UpdateTaxStatusAPiCall(id, status, setTaxStatus, TaxStatus);
  };

  const getFormInfo = (key) => (e, newValue) => {
    setValidationAlert({});
    const { value } = e.target;
    if (key === "includedPrice") {
      setTableData([tableDataInitial]);
      setAddCreateTax({ ...addCreateTax, includedPrice: e.target.checked });
    } else if (key === "amount") {
      // Validation for maximum 100
      const numericValue = parseFloat(value);
      if (numericValue > 100 || numericValue < 0) {
        setValidationAlert({ ...validationAlert, limit: "max: 100" });
      } else {
        setAddCreateTax({
          ...addCreateTax,
          amount: value,
        });
      }
    } else {
      setAddCreateTax({
        ...addCreateTax,
        [key]:
          value !== 0 && value !== undefined
            ? value
            : newValue !== null
            ? newValue
            : null,
      });
    }
  };

  const tableForm = (id, key) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData?.map((row, index) => {
      if (index === id) {
        return {
          ...row,
          [key]: newValue === undefined ? value : newValue,
          amount: newValue?.amount,
        };
      }
      return row;
    });
    setTableData(updatedData);
  };
  const addRow = () => {
    let updatedTableData = [...tableData, tableDataInitial];
    setTableData(updatedTableData);
  };
  const deleteRow = (index) => {
    let updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const tableDataToBackend = tableData
    ?.filter((obj) => obj?.tax !== null)
    ?.map((obj) => obj?.tax?._id);

  const bodyToApi = {
    taxName: addCreateTax?.taxName,
    taxScope: addCreateTax?.taxScope?.Scope,
    taxType: addCreateTax?.taxType?.Type,
    includedPrice: addCreateTax?.includedPrice || false,
    taxAccount:
      selectedButton === "Percentage of price"
        ? addCreateTax?.taxAccount?._id
        : null,
    amount: selectedButton === "Percentage of price" ? addCreateTax?.amount : 0,
    taxComputation: selectedButton,
    taxArray:
      tableDataToBackend?.length !== 0 && selectedButton === "Group of tax"
        ? tableDataToBackend
        : [],
  };

  //   useEffect(() => {

  //     if (isEnabled===true) {
  //         editCompanyProfileInfoAPICall({ enableHsnCode: isEnabled });
  //     }
  // }, [isEnabled]);

  const SaveTaxBtn = (key) => () => {
    const errorMessage = "fill this field !!";

    if (addCreateTax?.taxName === "") {
      setValidationAlert({ ...validationAlert, taxName: errorMessage });
    } else if (addCreateTax?.taxScope === null) {
      setValidationAlert({ ...validationAlert, taxScope: errorMessage });
    } else if (addCreateTax?.taxType === null) {
      setValidationAlert({ ...validationAlert, taxType: errorMessage });
    } else if (
      selectedButton === "Percentage of price" &&
      addCreateTax?.taxAccount === null
    ) {
      setValidationAlert({ ...validationAlert, taxAccount: errorMessage });
    } else if (
      selectedButton === "Percentage of price" &&
      addCreateTax?.amount === ""
    ) {
      setValidationAlert({ ...validationAlert, amount: errorMessage });
    } else if (
      selectedButton === "Group of tax" &&
      tableDataToBackend?.length === 0
    ) {
      dispatch(setSnackMsg("Add any group of tax in table !!"));
      dispatch(setErrorSnack(true));
    } else {
      if (key === "create") {
        createTaxSettingsApicall(
          bodyToApi,
          setSnackBarStates,
          snackBarStates,
          setCreateTax
        );
      }
      if (key === "edit") {
        let editPayload = { ...bodyToApi };
        editPayload._id = SingleViewTax?._id;
        updateTaxSettingsApiCall(
          editPayload,
          setSnackBarStates,
          snackBarStates,
          setConfEditShow,
          setDisabledInput
        );
      }
    }
  };
  const handleToggle = (checked) => {
    if (checked) {
      setIsDialogOpen(true);
    } else {
      updateHsnCode(false); // Update the API directly when toggled off
    }
  };

  const updateHsnCode = (enableHsnCode) => {
    const formData = { enableHsnCode };
    editCompanyProfileInfoAPICall(
      formData,
      setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg
    );
    setIsEnabled(enableHsnCode);
  };

  const handleDialogSubmit = () => {
    updateHsnCode(true);
    setIsDialogOpen(false);
  };

  const handleDialogCancel = () => {
    setIsDialogOpen(false);
    setIsEnabled(false);
  };

  useEffect(() => {
    if (SingleViewTax !== null && chartOfAccountList !== undefined) {
      setAddCreateTax({
        ...addCreateTax,
        taxName: SingleViewTax?.taxName || "",
        taxScope: filterObjFromList(
          "Scope",
          TaxScop,
          "taxScope",
          SingleViewTax
        ),
        taxType: filterObjFromList("Type", Tax_type, "taxType", SingleViewTax),
        includedPrice: SingleViewTax?.includedPrice,
        taxAccount: filterObjFromList(
          "_id",
          chartOfAccountList,
          "taxAccount",
          SingleViewTax
        ),
        amount: SingleViewTax?.amount || "",
      });
      setSelectedButton(SingleViewTax?.taxComputation);
      const updatedArray = SingleViewTax?.taxArray?.map((r, i) => {
        let taxObj = TaxList?.list?.filter((obj) => obj?._id === r);
        return {
          tax: taxObj !== undefined && taxObj[0],
          amount: taxObj !== undefined && taxObj[0]?.amount,
        };
      });
      setTableData(updatedArray);
    }
  }, [SingleViewTax, chartOfAccountList, TaxList]);
  useEffect(() => {
    if (FormDataInfo.length !== 0) {
      let loadData =
        window.location.pathname === filtereDataOptions?.filterActive;
      !loadData &&
        TaxSettingsFilterAPICall({
          status:
            FormDataInfo.status.length !== 0
              ? FormDataInfo.status[0] === "ACTIVE"
                ? true
                : false
              : null,
          search: FormDataInfo.search,
          index: currentPage - 1,
        });
    }
  }, [FormDataInfo, currentPage, CreateTax, TaxStatus]);

  useEffect(() => {
    chartOfAccountListApiCall();
    companyProfileInfoForUser();
  }, []);

  return (
    <>
      {CreateTax ? (
        <>
          <div
            className="global-white-bg-container"
            style={{ margin: "1% 1%", borderRadius: "4px" }}
          >
            <Tooltip title="Back">
              <IconButton onClick={() => setCreateTax(false)}>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
            {showSaveBtn ? (
              <Tooltip title="Save" onClick={SaveTaxBtn("create")}>
                <IconButton>
                  <i class="bi bi-floppy"></i>
                </IconButton>
              </Tooltip>
            ) : ConfEditShow === false ? (
              <Tooltip title="Edit">
                <IconButton onClick={TaxEdit}>
                  {isDisabled ? (
                    <i
                      class="bi bi-check2-square edit-icon"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                  ) : (
                    <i class="bi bi-pencil-square edit-icon1"></i>
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Update Tax">
                <IconButton onClick={SaveTaxBtn("edit")}>
                  <i
                    class="bi bi-check2-square edit-icon"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div
            className="global-white-bg-container"
            style={{
              margin: "1% ",
              padding: "2% 1%",
              borderRadius: "4px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div className="new-global-single-input" style={{ width: "47%" }}>
              <TextField
                id="outlined-basic"
                label="Tax Name"
                variant="outlined"
                type="text"
                focused
                value={addCreateTax?.taxName}
                onChange={getFormInfo("taxName")}
                disabled={disabledInput}
              />
              <p className="doc-validation-alert">{validationAlert?.taxName}</p>
            </div>
            <div className="new-global-single-input" style={{ width: "47%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={TaxScop || []}
                getOptionLabel={(option) => option.Scope}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tax Scop" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.Scope}>
                    <span>{option.Scope}</span>
                  </li>
                )}
                value={addCreateTax?.taxScope}
                onChange={getFormInfo("taxScope")}
                disabled={disabledInput}
              />
              <p className="doc-validation-alert">
                {validationAlert?.taxScope}
              </p>
            </div>
            <div className="new-global-single-input" style={{ width: "47%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={Tax_type || []}
                getOptionLabel={(option) => option.Type}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tax Type" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.Type}</span>
                  </li>
                )}
                value={addCreateTax?.taxType}
                onChange={getFormInfo("taxType")}
                disabled={disabledInput}
              />
              <p className="doc-validation-alert">{validationAlert?.taxType}</p>
            </div>
            <div className="global-single-input1" style={{ width: "30%" }}>
              <p>Included Price</p>
              <input
                className="TaxCreateCheckBox"
                type="checkbox"
                checked={addCreateTax?.includedPrice}
                onChange={getFormInfo("includedPrice")}
                disabled={disabledInput}
              />
            </div>
          </div>
          <div
            className="global-white-bg-container"
            style={{ margin: "1% 1%", borderRadius: "4px" }}
          >
            <h3>Tax Computation</h3>

            <div
              style={{
                display: "flex",
                width: "60%",
                justifyContent: "space-around",
              }}
            >
              <div>
                <input
                  type="radio"
                  id="PercentageOfPrice"
                  name="taxComputation"
                  value="Percentage of price"
                  checked={selectedButton === "Percentage of price"}
                  style={{ accentColor: "#000", cursor: "pointer" }}
                  onChange={handleButtonClick}
                  disabled={disabledInput}
                />
                <label
                  htmlFor="PercentageOfPrice"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  Percentage Of Price
                </label>
              </div>
              <div>
                <input
                  name="taxComputation"
                  type="radio"
                  id="GroupOfTaxes"
                  value="Group of tax"
                  checked={selectedButton === "Group of tax"}
                  style={{ accentColor: "#000", cursor: "pointer" }}
                  onChange={handleButtonClick}
                  disabled={disabledInput}
                />
                <label
                  htmlFor="GroupOfTaxes"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    cursor: "pointer",
                    marginBottom: "8px",
                  }}
                >
                  {" "}
                  Group Of Taxes
                </label>
              </div>
            </div>
            {selectedButton === "Percentage of price" ? (
              <>
                <div style={{ display: "flex", margin: "2%", padding: "2%" }}>
                  <div
                    className="new-global-single-input"
                    style={{ width: "47%" }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={chartOfAccountList || []}
                      getOptionLabel={(option) => option?.accountName}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Tax Account" focused />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          <span>{option?.accountName}</span>
                        </li>
                      )}
                      value={addCreateTax?.taxAccount}
                      onChange={getFormInfo("taxAccount")}
                      disabled={disabledInput}
                    />
                    <p className="doc-validation-alert">
                      {validationAlert?.taxAccount}
                    </p>
                  </div>
                  <div
                    className="new-global-single-input"
                    style={{ width: "47%" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Amount"
                      variant="outlined"
                      type="text"
                      focused
                      value={addCreateTax?.amount}
                      onChange={getFormInfo("amount")}
                      disabled={disabledInput}
                      onFocus={(e) => e.target.select()}
                    />
                    {
                      <p className="doc-validation-alert">
                        {validationAlert?.amount || validationAlert?.limit}
                      </p>
                    }
                  </div>
                </div>
              </>
            ) : (
              <div
                className="global-product-table special-items-table"
                style={{ margin: "1%" }}
              >
                <table style={{ borderBottom: "2px solid #dee3f8" }}>
                  <thead>
                    <tr>
                      <th>Tax</th>
                      <th>Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((r, index) => (
                      <tr key={index}>
                        <td style={{ paddingTop: "0" }}>
                          <div className="product-table-auto-complete">
                            <Autocomplete
                              options={
                                typeNoneTax?.filter(
                                  (obj) =>
                                    obj?.includedPrice ===
                                    addCreateTax?.includedPrice
                                ) || []
                              }
                              style={{ maxHeight: "100px" }}
                              getOptionLabel={(option) => option?.taxName}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option._id}>
                                  <span>{option?.taxName}</span>
                                </li>
                              )}
                              value={r?.tax}
                              onChange={tableForm(index, "tax")}
                              disabled={disabledInput}
                            />
                          </div>
                        </td>
                        <td style={{ paddingTop: "0" }}>
                          <input
                            type="number"
                            value={r?.amount}
                            disabled={disabledInput}
                          />
                        </td>

                        <td style={{ paddingTop: "0" }}>
                          <IconButton
                            onClick={() => deleteRow(index)}
                            disabled={disabledInput}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete"
                    style={{
                      width: "76%",
                      fontSize: "0.9rem",
                      paddingLeft: "7.5px",
                      fontWeight: "600",
                      color: "rgb(134, 152, 229)",
                      cursor: "pointer",
                    }}
                    onClick={() => addRow()}
                  >
                    Add Line
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="utility-settings-container">
          <div
            className="utility-settings-menu-container"
            style={{ padding: "0px 4px" }}
          >
            <div
              className="justify-space-between"
              style={{ gap: "0 12px", justifyContent: "flex-end" }}
            >
              <CategorySearchandFilter
                statusOptions={["ACTIVE", "INACTIVE"]}
                onData={handleDataFromChild}
                isStatus={true}
                isDate={false}
                isBranch={false}
                onBranchSelect={handleBranchSelect}
              />
              <button onClick={CreateTaxbtn} className="create-button-blue">
                Create Tax
              </button>
            </div>
            <div
              className="justify-center"
              style={{ justifyContent: "space-between" }}
            >
              <label className="flex items-center gap-3">
                Enable HSN Code
                <input
                  style={{ top: "7px", marginLeft: "10px" }}
                  className="toggle-checkbox"
                  type="checkbox"
                  checked={isEnabled}
                  onChange={(e) => handleToggle(e.target.checked)}
                />
              </label>

              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>

            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Tax Name</th>
                    <th>Tax Type</th>
                    <th>Active/Deactive</th>
                  </tr>
                </thead>
                <tbody>
                  {TaxList?.list !== undefined ? (
                    TaxList?.list
                      ?.slice(0)

                      ?.map((r, i) => (
                        <tr
                          style={{ cursor: "pointer" }}
                          title="View Single Tax"
                        >
                          <td onClick={() => singleViewTax(r)}>{i + 1}</td>
                          <td onClick={() => singleViewTax(r)}>{r?.taxName}</td>
                          <td onClick={() => singleViewTax(r)}>{r?.taxType}</td>
                          <td>
                            <input
                              onClick={() => TaxUpdate(r._id, r?.status)}
                              className="toggle-checkbox"
                              type="checkbox"
                              checked={r?.status}
                            />
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={4}>No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <Dialog
            open={isDialogOpen}
            onClose={handleDialogCancel}
            PaperProps={{ style: { padding: "20px" } }}
          >
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Enable HSN Code
            </DialogTitle>
            <DialogContent>
              <Typography align="center" variant="body1" color="textSecondary">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                efficitur.
              </Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  width: "160px",
                }}
                onClick={handleDialogCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "green",
                  color: "white",
                  width: "160px",
                }}
                onClick={handleDialogSubmit}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <SuccessSnackbar
            open={openSuccessSnack}
            handleClose={closeSnackbar}
            message={snackMsg}
          />
          <ErrorSnackbar
            open={openErrorSnack}
            handleClose={closeSnackbar}
            message={snackMsg}
          />
        </div>
      )}
    </>
  );
};
