import React, { useEffect, useRef, useState } from "react";
import { getCurrentTime, previousDate, today } from "../../../../../Js/Date";
import { useSelector } from "react-redux";
import {
  convertArrayToExcel,
  filterObjFromList,
} from "../../../../../Js/generalFunctions";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { AccountPayableAPIcall } from "./partnerReportApi";
import { useNavigate } from "react-router-dom";
import Template from "./AccountPrint";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../../Redux/store";
import ExcelJS from "exceljs";
import PrintComponent from "../../../../print/report/PrintComponent";

import TableListActionButtons from "../../../../Single Components/ActionButtons/TableListActionButtons";

export const AccountPayable = () => {
  let paymentsPrintNew = useRef();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  let navigate = useNavigate();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [symbol, setSymbol] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const [filteredData, setFilteredData] = useState({});
  const accountpayable = useSelector(
    (state) => state?.PartnerReport?.AccountPayable
  );

  // console.log(accountpayable);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };

  const initialvalue = {
    label: "Account Payable Report",
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  };

  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(initialvalue);

  useEffect(() => {
    setFormValues({
      ...formValues,
      totalBalance: accountpayable?.totalBalance || 0,
      totalCredit: accountpayable?.totalCredit || 0,
      totalDebit: accountpayable?.totalDebit || 0,

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
    });
    setData(accountpayable?.vendors);
  }, [accountpayable, companyProfileData]);

  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }
    // console.log(filters?.fromDate);

    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }

    return parts; // Return the array directly
  };

  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Account Payable");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Account Payable"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:D1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:D${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow(["Vendor", "Debit", "Credit", "Balance"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Vendor", key: "vendorName", width: 50 },
      { header: "Debit", key: "debit", width: 20 },
      { header: "Credit", key: "credit", width: 20 },
      { header: "Account Payable", key: "balance", width: 20 },
    ];

    accountpayable?.vendors?.forEach((item) => {
      const row = sheet.addRow({
        vendorName: item.vendorName,
        debit: `${symbol} ${item.debit?.toFixed(2)}`,
        credit: `${symbol} ${item?.credit?.toFixed(2)}`,
        balance: `${symbol} ${item?.balance?.toFixed(2)}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber === 4) {
          // Assuming the balance column is the 4th column
          const value = parseFloat(item?.balance);
          if (value < 0) {
            cell.font = { color: { argb: "FFFF0000" } }; // Red color for negative values
          }
        }
      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      `${symbol} ${accountpayable?.totalDebit.toFixed(2)}`,
      `${symbol} ${accountpayable?.totalCredit?.toFixed(2)}`,
      `${symbol} ${accountpayable?.totalBalance?.toFixed(2)}`,
    ]);

    totalRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Account Payable.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("fromDate", `${fromDate} 00:00:00`);
    localStorage.setItem("toDate", `${toDate} 23:59:59`);
    localStorage.setItem("vendor", row?.vendorName);

    // setSelectedHeading("Ledger Balance");
    store.dispatch(setFilterActive(window.location.pathname));
    navigate(
      "/userdashboard/accounts/report/partnerReport/accountPayable/singleView"
    );
  };

  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const handlePrint = useReactToPrint({
    content: () => paymentsPrintNew.current,
  });

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    if (configurationList !== undefined) {
      setToDate(configurationList?.yearEndDate);
      setFromDate(configurationList?.yearStartDate);
    }
  }, [configurationList]);

  useEffect(() => {
    if (FormDataInfo) {
      let body = {};

      if (FormDataInfo?.fromDate !== "") {
        body.startDate = `${FormDataInfo.fromDate} 00:00:00`;
      }

      if (FormDataInfo?.toDate !== "") {
        body.endDate = `${FormDataInfo.toDate} 23:59:59`;
      }

      if (FormDataInfo?.branchList && FormDataInfo?.branchList.length > 0) {
        body.branchId = FormDataInfo?.branchList[0];
      }

      if (FormDataInfo?.search) {
        body.search = FormDataInfo.search;
      }

      let loadData =
        window.location.pathname === filtereDataOptions?.filterActive;
      !loadData && AccountPayableAPIcall(body);
    }
  }, [FormDataInfo]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
        <h3>Account Payable Report</h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons
            downloadClick={downloadList}
            PrintClick={handlePrint}
          />
          <CategorySearchandFilter
            statusOptions={["PURCHASE ORDER", "DONE", "CANCEL"]}
            onData={handleDataFromChild}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>

      <div
        className="global-report-table-container updated-table-container"
        style={{ height: "calc(100vh - 145px)" }}
      >
        <table>
          <thead>
            <tr>
              <td>Vendor</td>
              <th style={{ width: "22%" }}>Debit</th>
              <th style={{ width: "22%" }}>Credit</th>
              <th style={{ width: "22%" }}>Balance</th>
            </tr>
          </thead>
          <tbody>
            {accountpayable && accountpayable?.vendors.length !== 0 ? (
              accountpayable?.vendors?.map((item, index) => (
                <tr key={index} onClick={() => handleClickRow(item)}>
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>
                    {item.vendorName}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {symbol}
                    {item.debit?.toFixed(decimalPosition)}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {symbol}
                    {item.credit?.toFixed(decimalPosition)}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {symbol}
                    {item.balance?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No Records</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th>
                {symbol}
                {accountpayable?.totalDebit?.toFixed(decimalPosition)}
              </th>
              <th>
                {symbol}
                {accountpayable?.totalCredit?.toFixed(decimalPosition)}
              </th>
              <th>
                {symbol}
                {accountpayable?.totalBalance?.toFixed(decimalPosition)}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>

      <div style={{ display: "none" }}>
        <div ref={paymentsPrintNew}>
          <PrintComponent header={"ACCOUNT PAYABLE REPORT"} pageHeight={50}>
            <table style={{ padding: "6px" }}>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#1d1da7", color: "#ffff" }}>
                    Vendor
                  </th>
                  <th style={{ backgroundColor: "#1d1da7", color: "#ffff" }}>
                    Debit
                  </th>
                  <th style={{ backgroundColor: "#1d1da7", color: "#ffff" }}>
                    Credit
                  </th>
                  <th style={{ backgroundColor: "#1d1da7", color: "#ffff" }}>
                    Balance
                  </th>
                </tr>
              </thead>
              <tbody>
                {accountpayable?.vendors?.map((item, index) => (
                  <>
                    <tr key={index} onClick={() => handleClickRow(item)}>
                      <td
                        style={{ textAlignLast: "start", paddingLeft: "10px" }}
                      >
                        {item?.vendorName || "---"}
                      </td>
                      <td
                        style={{ textAlignLast: "end", paddingRight: "10px" }}
                      >
                        {symbol}
                        {item?.debit?.toFixed(decimalPosition) || "---"}
                      </td>
                      <td
                        style={{ textAlignLast: "end", paddingRight: "10px" }}
                      >
                        {symbol}
                        {item?.credit?.toFixed(decimalPosition) || "---"}
                      </td>
                      <td
                        style={{ textAlignLast: "end", paddingRight: "10px" }}
                      >
                        {symbol}
                        {item?.balance?.toFixed(decimalPosition) || "---"}
                      </td>
                    </tr>
                    {accountpayable?.vendors?.length - 1 === index && (
                      <tr className="new-layout-print-footer">
                        <td>acc
                          Total
                        </td>
                        <td
                          style={{
                            backgroundColor: "#1d1da7",
                            color: "#ffff",
                            fontWeight: 600,
                            textAlign: "right",
                          }}
                        >
                          {symbol}
                          {accountpayable?.totalDebit?.toFixed(decimalPosition)}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#1d1da7",
                            color: "#ffff",
                            fontWeight: 600,
                            textAlign: "right",
                          }}
                        >
                          {symbol}
                          {accountpayable?.totalCredit?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#1d1da7",
                            color: "#ffff",
                            fontWeight: 600,
                            textAlign: "right",
                          }}
                        >
                          {symbol}
                          {accountpayable?.totalBalance?.toFixed(
                            decimalPosition
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </PrintComponent>
        </div>
      </div>
    </div>
  );
};
