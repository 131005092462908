import React, { useEffect, useState } from "react";
import "./posReceiptTemplateStyle.css";
import { useSelector } from "react-redux";
import language from "../../i18n/languages.json";
import { convertToArabicMobileNo, convertToArabicNumber, numToArabicWords, numToWords } from "../../../../../Js/generalFunctions";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import { convertDateFormat, convertToAMPM } from "../../../../../Js/Date";

const PosReceiptTemplate = (props) => {
   

    const body = {
      query: "good!",
      sourceLang: language.English,
      targetLang: language.Arabic,
    };

    const decimalPosition=localStorage.getItem("decimalPosition")
    const { posTemplateSelected } = useSelector((state) => state.posReceiptSlice);
    const { receiptData } = useSelector((state) => state.viewOrdersBillingSlice);
    const companyProfileData =useSelector((state) => state?.companyProfileSlice?.userValue)
 
    const [tempalteType,setTemplateType]=useState(posTemplateSelected?.posReceiptTemplate)
    const [domain,setDomain]=useState(null)


    const {dialogOpen} = useSelector((state) => state.billingScreenSlice);
    const posSettings=useSelector((state)=>state.posSettingSlice.apiResList)

    useEffect(()=>{
      if(receiptData!==null){
      
      const domainAddress = `${window.location.protocol}//${window.location.host}/posReaderPreview?path=admin/viewqrcodedata&db=${encodeURIComponent(receiptData?.db)}&vendororcusid=${encodeURIComponent(receiptData?.cusId)}&type=${encodeURIComponent("POS")}&orderId=${encodeURIComponent(receiptData?._id)}`;
      setDomain(domainAddress);
      }
    },[receiptData])
    return (
      <div className="pos-receipt-template-container">
        {/* <button onClick={() => translateLang()}>translate </button> */}
        <div className="pos-receipt-template-header">
          {/* <!--Company-logo-> */}
          {
            companyProfileData?.logo &&
            <div className="template-header-company-logo">
              <img src={companyProfileData.logo} alt="logo" onError={(e) => { e.target.style.display = "none"; }} />
            </div>
          }
          {/* <!--Bill-header->  */}
          <div className="template-header-company-info">
            <span className="template-restaurant-name">
              {companyProfileData?.companyName||props?.companyName|| "---"}
            </span>
            <span style={{textAlign:"center"}}>{""} </span>
            {/* <span>{companyProfileData?.country||props?.country|| "---"} </span> */}

            <span>{companyProfileData?.companyEmail||props?.email|| "---"} </span>
      
            {/* <!--Bill-header-> arabic */}
            <>
              {/* <span>برج زي - مدينة دبي للإنترنت </span>
              <span>دبي الامارات العربية المتحدة</span> */}
              <span>{companyProfileData?.contactNumber||props?.contactNumber|| "---"} </span>
            </>
            {
              (receiptData?.trnNumber || props?.trnNumber )&&
              <span className="template-transaction-number">TRN : {receiptData?.trnNumber||props?.trnNumber }</span>}
          </div>
        </div>
        <div className="pos-receipt-template-body">
          <hr className="template-dashed-line" />
          {/* <!--Bill-Details-> */}
          <div className="template-invoice-container">
            <div className="template-invoice-details">
              <span className="temp-bol-txt">BILL NO</span>:
              <span className="temp-bol-txt">{receiptData?.name || "---"}</span>
            </div>
            <div className="template-invoice-details temp-text-end">
              {/* <span className="temp-bol-txt ">ORDER TYPE</span> */}
              <span className="temp-bol-txt ">&nbsp;{receiptData?.ordType}</span>
              {/* <span className="temp-bol-txt ">تناول الطعام في</span> */}
            </div>
    
            {
              <div className="template-invoice-details">
                <span >CUSTOMER :</span>
                <span >{receiptData?.customerName?.name||receiptData?.customerName}</span>
              </div>
            }
            <div className="template-invoice-details temp-text-end">
              {/* <span>DATE & TIME :</span> */}
              <span>{convertDateFormat(receiptData?.orderDate.split(' ')[0])}  &nbsp;
                {convertToAMPM(receiptData?.orderDate.split(' ')[1])}</span>
            </div>
            {
              <div className="template-invoice-details">
                <span >MOBILE:</span>
                <span >{receiptData?.customerName?.mobileNo||receiptData?.mobileNo}</span>
              </div>
            }
           {receiptData?.cashier ?  <div className="template-invoice-details temp-text-end">
              <span>CASHIER :</span>
              <span>{receiptData?.cashier}</span>
            </div> :<div></div>}

            {
              <div className="template-invoice-details">
                <span >ADDRESS:</span>
                <span >
                  {receiptData?.customerName?.city && receiptData.customerName.city + ','}
                  {receiptData?.customerName?.streetName && receiptData.customerName.streetName}
                  <br />
                  {receiptData?.customerName?.buildingNo && receiptData.customerName.buildingNo + '-'}
                  {receiptData?.customerName?.roomNo && receiptData.customerName.roomNo}
                  </span>
              </div>
            }
            {
              receiptData?.waiterName?
            <div className="template-invoice-details temp-text-end">
              <span>WAITER&nbsp; :</span>
              <span>{receiptData?.waiterName}</span>
            </div>:
            <div></div>
            }
            {
              receiptData?.tables.length!==0 ?
              <div className="template-invoice-details">
                <span >TABLE NO :</span>
                <span >{receiptData?.tables}</span>
              </div>:<div></div>
            }
            {/* <div></div> */}
            <div className="template-invoice-details temp-text-end">
              <span >KOT NO</span>:
              <span >{receiptData?.kotInfo?.join(",") || "-NIL-"}</span>
            </div>

          </div>
          <hr className="template-dashed-line" />

          {/* <!--table--> */}
          <div className="template-table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="temp-table-head">
                      <span>Item</span>
                      {tempalteType===3 && <span>غرض</span>}
                    </div>
                  </th>
                  <th>
                    <div className="temp-table-head">
                      <span>Qty</span>
                      {tempalteType===3 && <span>الكمية</span>}
                    </div>
                  </th>
                  <th>
                    <div className="temp-table-head">
                      <span>Price</span>
                      {tempalteType===3 && <span>سعر</span>}
                    </div>
                  </th>
                  <th>
                    <div className="temp-table-head">
                      <span>Amount</span>
                      {tempalteType===3 && <span>كمية</span>}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {receiptData?.orderInfo?.length > 0 ? (
                  receiptData?.orderInfo?.map((res, i) => (
                    <tr key={i}>
                      <td>
                        <div className="table-item-center" style={{textAlign:"start"}}>
                          <span className="temp-bol-txt">
                            {res?.productName}
                          </span>
                          {tempalteType===3 && <span className="temp-bol-txt">{res?.nativeName}</span>}
                        </div>
                      </td>
                      <td>
                        <div className="table-item-center" style={{textAlign:"end"}}>
                          <span>{res?.qty?.toFixed(decimalPosition)}</span>
                        </div>
                      </td>
                      <td>
                        <div className="table-item-center">
                          <span>{res?.unitPrice?.toFixed(decimalPosition)}</span>
                        </div>
                      </td>
                      <td>
                        <div className="table-item-center" style={{textAlign:"end"}}>
                          <span>{res?.subTotalWoTax?.toFixed(decimalPosition)}</span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>NO DATA</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td colSpan={2} className="table-item">
                    <span>Taxable Amount</span>
                    {
                      tempalteType===3&&
                    <span>المبلغ الخاضع للضريبة</span>
                    }
                  </td>
                  <td colSpan={2} className="temp-text-end">{tempalteType===3&&
                   <span>  {convertToArabicNumber(Number(receiptData?.taxableAmt?.toFixed(decimalPosition)))} &nbsp;  &nbsp;&nbsp; </span>}
                   <span> {receiptData?.taxableAmt?.toFixed(decimalPosition)}</span>
                  </td>
                </tr>
                {
                  receiptData?.taxDetails.length!==0 &&
                  receiptData?.taxDetails.map((item,i)=>(
                    <tr key={i}>
                      <td></td>
                      <td colSpan={2}>
                        <span>{item?.taxName}</span>
                      </td>
                      <td className="temp-text-end">{tempalteType===3&&
                      <span> {convertToArabicNumber(Number(item?.amount?.toFixed(decimalPosition)))} &nbsp; &nbsp;&nbsp;</span>}
                      <span>  {item?.amount?.toFixed(decimalPosition)}</span>
                      </td>
                    </tr>
                  ))
                }
                {
                receiptData?.deliveryCharge>0 &&
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <span>Delivery Charge</span>
                  </td>
                  <td className="temp-text-end">{tempalteType===3&&
                    <span> {convertToArabicNumber(Number(receiptData?.deliveryCharge?.toFixed(decimalPosition)))} &nbsp; &nbsp;&nbsp;</span>}
                    <span>{receiptData?.deliveryCharge?.toFixed(decimalPosition)}</span>
                    </td>
                </tr>
                }
                {
                receiptData?.pkgCharge>0 &&
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <span>Package Charge</span>
                  </td>
                  <td className="temp-text-end">{tempalteType===3&&
                    <span> {convertToArabicNumber(Number(receiptData?.pkgCharge?.toFixed(decimalPosition)))} &nbsp; &nbsp;&nbsp;</span>}
                    <span>{receiptData?.pkgCharge?.toFixed(decimalPosition)}</span>
                    </td>
                </tr>}
                {
                receiptData?.discountAmt>0 &&
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <span>Discount</span>
                  </td>
                  <td className="temp-text-end">{tempalteType===3&&
                    <span> {convertToArabicNumber(Number(receiptData?.discountAmt?.toFixed(decimalPosition)))} &nbsp; &nbsp;&nbsp;</span>}
                    <span>{receiptData?.discountAmt?.toFixed(decimalPosition)}</span>
                    </td>
                </tr>}
                <tr className="temp-net-amount">
                  <td></td>
                  <td colSpan={2} className="table-item">
                    <span className="temp-bol-txt">Net Amount</span>{tempalteType===3&&
                    <span className="temp-bol-txt">كمية الشبكة</span>}
                  </td>
                  <td colSpan={2} className="temp-text-end temp-bol-txt">
                    {" "}{tempalteType===3&&<span>{convertToArabicNumber(Number(receiptData?.amtTotal?.toFixed(decimalPosition)))} &nbsp;&nbsp;&nbsp;</span>
                    }
                    {
                    <span>{receiptData?.amtTotal?.toFixed(decimalPosition)}</span>
                    }
                   </td>
                </tr>
                <tr className="temp-last-raw">
                  <td colSpan={4} className="temp-text-end temp-bol-txt">
                    <div>
                      {
                        <span>{numToWords(Number(receiptData?.amtTotal?.toFixed(decimalPosition)))}</span>
                      }
                      {/* <span>{numToArabicWords(receiptData?.amtTotal)}</span> */}
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <hr className="template-dashed-line" />
          {/* <!--payment-details--> */}
          <div className="template-payment-details">
            <div className="template-pax-count">
              <div>
                <span className="temp-bol-txt">PAX :</span>
                <span className="temp-bol-txt">{receiptData?.orderInfo?.length}</span>
              </div>
             {tempalteType===3&& 
             <div>
                <span className="temp-bol-txt">باكس :</span>
                <span className="temp-bol-txt">{convertToArabicNumber(receiptData?.orderInfo?.length)}</span>
              </div>}
            </div>
            <div className="template-payment-via">
              <div className="temp-text-end justify-space-between">
                <span className="temp-bol-txt ">PAYMENT</span>
               {tempalteType===3&& <span style={{marginLeft:"12px"}} className="temp-bol-txt ">قسط</span>}
              </div>
             {
             receiptData?.paymentInfo?.map((item,i)=>(
               <div key={i} className="temp-text-end justify-space-between">
                 <span className="temp-bol-txt ">{item.type?.toUpperCase()}</span>
                 {tempalteType===3&&
                 <span className="temp-bol-txt ">&nbsp; &nbsp;{convertToArabicNumber(Number(item.amount?.toFixed(decimalPosition)))} &nbsp;</span>}
                 <span className="temp-bol-txt " style={{marginLeft:"12px"}}>{item.amount?.toFixed(decimalPosition)}</span>
               </div>
             ))
             }
            </div>
          </div>
          <hr className="template-dashed-line" />
          {/* <!--Token--> */}
          {tempalteType===2&&
          <>
          <div className="template-token-container">
            <div>
              <span className="temp-token-name">YOUR TOKEN NO</span>
              <span className="temp-token-no">007</span>
              {/* <span className="temp-token-no">{receiptData?.kotInfo}</span> */}
            </div>
          </div>

          <hr className="template-dashed-line" />
          </>  
          }
        </div>
        <div className="pos-receipt-template-footer">
          <div className="template-footer-content">
            {/* <div className="temp-two-column">
              <span className="temp-bol-txt fr-greeting">THANK YOU</span>{
                tempalteType===3&&
              <span className="temp-bol-txt fr-greeting">شكرًا لك</span>}
            </div> */}
            {/* <div className="template-ft-ph-container">
              <span className="temp-bol-txt">CALL FOR DELIVERY </span>
              <span className="temp-bol-txt">+91 4543 5544 77 /</span>
              <span className="temp-bol-txt">+91 8943 5544 78</span>
            </div> */}
            {/* {
            tempalteType===3&&  
            <div className="template-ft-ph-container">
              <span className="temp-bol-txt">{convertToArabicMobileNo(` 23456789`)}</span>
              <span className="temp-bol-txt">اتصل للتسليم </span>
            </div>
            } */}
            {/* <div className="temp-two-column">
              <span className="temp-bol-txt footer-summary fr-greeting">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iusto,
                architecto!
              </span>
              {
                tempalteType===3&&
              <span className="temp-bol-txt footer-summary fr-greeting">
                لوريم إيبسوم دولور الجلوس، أميت كونسيكتور أديبيسينج إيليت.
                يوستو, مهندس معماري!
              </span>}
            </div> */}
            <div className="template-ft-qr-bar-code-container justify-center" style={{gap:"1em",marginTop:"1em",flexWrap:"wrap"}}>
              <div className="template-ft-qr">
                <QRCode
                  size={80}
                  value={domain ? domain : ''}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <div className="template-ft-bar">
                <Barcode
                  format="CODE128"
                  value={receiptData?.name || "###"}
                  width={1.5}
                  fontSize={12}
                  height={60}
                  margin={0}
                />
              </div>
            </div>
            <hr className="template-dotted-line" />
            <div className="temp-two-column">
              <span className="temp-bol-txt fr-greeting">THANK YOU</span>{
                tempalteType===3&&
              <span className="temp-bol-txt fr-greeting">شكرًا لك</span>}
            </div>
            {/* <div className="temp-two-column">
              <span>Send Hi to {"+91 0004 5556 66"} get our latest offer</span>
              {
                tempalteType===3&&
                <span>
                Send Hi to {"+91 0004 5556 66"} احصل على أحدث عرض لدينا
              </span>}
            </div> */}
            <hr className="template-dashed-line" />
          </div>
        </div>
      </div>
    );
  }
;

export default PosReceiptTemplate;
