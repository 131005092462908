import {
  Autocomplete,
  Dialog,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTime, today } from "../../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import {
  getInvoicesListOfCustomerAPI
} from "../CustomerInvoice/customerInvoiceAPI";
import {
  viewAllAllowedBranchesAPICall
} from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  findObjFromList,
  roundNumericFields,
  taxCalculationForLines,
} from "../../../../../Js/generalFunctions";
import { journalListApiCall } from "../../../../../API/AccountUpdated/journalAPI";
import { viewMultiCurrencyConfiguration } from "../../../../../API/Purchase Manager/configuration";
import {
  viewUOMSubListAPICall
} from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import { getAllTaxSettingsListAPICall } from "../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";
import {
  crediNotesSingleViewAPICall,
  customerCreditNoteRegisterPaymentPostAPICall,
  customerInvoiceSingleData,
  draftCreditNoteAPICall,
  editCreditNoteAPICall,
  generateCreditNoteNameAPICall,
  postCreditNoteAPICall,
} from "./customerCreditNotesAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import PaymentTerms from "../../../components/Dialog/paymentTerms/PaymentTerms";
import { set_Back_button_action, setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import { setErrorSnack, setSnackMsg } from "../../../Sales/Billing/Slice/billingScreenSlice";
import { TableVirtuoso } from "react-virtuoso";
import { customerFilterOptions } from "../../../../Single Components/GneralUtilities";

export const CreateCustomerCreditNotes = () => {

  const {userDetails,branchDetails,currencyDetails} = useSelector((state) => state.mainDashBoardSlice)

  let singleViewId = localStorage.getItem("singleViewById");
  const numToWords = require("num-words");
  
  const dispatch=useDispatch()


  const decimalPosition = Number(localStorage.getItem("decimalPosition"));

  const editedData = useSelector(
    (state) => state.customerCreditNoteSlice.rowData
  );
  const paymentTermsList = useSelector(
    (state) => state.vendorsListSlice?.paymentTermsList
  );

  const singleRowId = localStorage.getItem("singleViewById");
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );

  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );


  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );

  const accountsList = chartOfAccountList?.filter(
    (item) => item?.type === "Income"
  );
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.userValue
  );
  const journalList = useSelector((state) => state.journalSlice.value);
  const journalSalesList = journalList?.filter(
    (item) => item?.type === "sales"
  );

  const invoiceList = useSelector(
    (state) => state.customerInvoiceSlice.customerInvoices
  );
  const invoiceData = useSelector(
    (state) => state.customerInvoiceSlice.singleResList
  );

  const MultiCurrencyView = useSelector(
    (state) => state.ConfigurationSlice.MultiCurrency
  );

  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );

  const allTaxListSalesType = allTaxList?.filter(
    (item) => item?.taxType === "Sales" 
  );
  let navigateTo = useNavigate();
  const [registerPayment, setRegisterPayment] = useState(false);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const addRowData = ["Add Header","Add Line"];
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState("Add Line");
  const [creditNoteName, setCreditNoteName] = useState("###");
  const [editDataId, setEditDataId] = useState("");
  const [handlePaymentTermsbtn, setHandlePaymentTermsbtn] = useState("");
  const [totalsInfo, setTotalsInfo] = useState({
    subTotal: 0.0,
    inclusiveTax: 0.0,
    exclusiveTax: 0.0,
    total: 0.0,
    roundOff:0?.toFixed(decimalPosition)
  });


  const tableDataInitial = {
    type: "lines",
    id: Date.now(),
    product: null,
    label: "",
    account: null,
    uom: null,
    qty: "",
    unitPrice: "",
    discount: "",
    tax: [],
    amount: "",
    inclusive: "",
    header: "",
  };
  const [tableData, setTableData] = useState([tableDataInitial]);

  const [filteredTaxOptions, setFilteredTaxOptions] = useState(
    tableData?.map(() => allTaxListSalesType || [])
  );

  const [rowIndex, setRowIndex] = useState(null);

  const [image, setImage] = useState([]);
  const [imageDisplay, setImageDisplay] = useState([]);
  const [receivedImgUrl, setReceivedImgUrl] = useState([]);

  const [logView, setLogView] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [editClicked, setIsEditClicked] = useState(false);
  const containerRef = useRef(null);
  const [validationAlert, setValidationAlert] = useState({});

  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });

  const [loadingForm, setLoadingForm] = useState(false);

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const [log, setLog] = useState([]);

  const formInitialState = {
    customer: null,
    invoice: null,
    branch: branchDetails,
    employee: userDetails,
    invoiceReference: "",
    invoiceDate: today,
    paymentReference: "",
    accountingDate: today,
    journal: findObjFromList("_id",journalList,"salesJournal",configurationList?.sales),
    currency: currencyDetails,
    orderDate: null,
    paymentTerms: null,
    customerNotes: "",
    termsAndConditions: "",

    isEdit: false,
    status: "",
    registerPaymentChequeDate: "",
    registerPaymentAmount: "",
    registerPaymentDate: today,
    paymentsDone: [],
    paymentType: null,
    type: "Manual",
    chequeNo: "",
  };

  const [formValues, setFormValues] = useState(formInitialState);
  const clearForm = () => {
    setFormValues(formInitialState);
    setTableData([tableDataInitial]);
    setEditDataId("");
  };
  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };

  const [addRowScroll, setAddRowScroll] = useState(false);
  const addRow = () => {
    if (addRowInput === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), header: "" },
      ]);
    } else if (addRowInput === "Add Line") {
      let lastRow=tableData[tableData.length-1]
      if(lastRow?.product===null){
        dispatch(setSnackMsg("Fill all the required fields in the last row !!"))
        dispatch(setErrorSnack(true))
      }else{
        setTableData([
          ...tableData,
          {
            type: "lines",
            id: Date.now(),
            product: null,
            label: "",
            account: null,
            uom: null,
            qty: "",
            unitPrice: "",
            discount: "",
            tax: [],
            amount: "",
            inclusive: "",
          },
        ]);
      }
     
    }
    setAddRowScroll(!addRowScroll);
  };
  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef?.current?.scrollIntoView({index:tableData.length,behavior:"smooth",align:"end",behaviour:"smooth"});
      }, 0);
    }
  }, [addRowScroll]);


  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setImageDisplay([
      ...imageDisplay,
      { url: URL.createObjectURL(e.target.files[0]) },
    ]);
  };

  const removeImageFn = (i) => {
    if (editedData === undefined) {
      image.splice(i, 1);
    } else {
      receivedImgUrl?.length !== 0 &&
        image.splice(i - receivedImgUrl?.length, 1);
      receivedImgUrl.splice(i, 1);
    }

    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
    setReceivedImgUrl([...receivedImgUrl]);
  };

  const clickLogView = () => {
    setLogView(!logView);
  };
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    const keyCode = e.keyCode || e.which;
    setValidationAlert({});
    setFormValues({
      ...formValues,
      [key]:
        value !== 0 && value !== undefined
          ? value
          : newValue !== null
          ? newValue
          : null,
    });
    if (keyCode === 13) {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]: newValue !== null ? newValue : null,
      });
    } else if (key === "customer") {
      
      newValue ?getInvoicesListOfCustomerAPI({ customerId: newValue?._id }) :setFormValues({ ...formValues, invoice:null,customer:null,branch:null  }) ;
    } else if (key === "invoice") {
      if(newValue === null || newValue ===undefined){
        setFormValues(formInitialState)
        setTableData([tableDataInitial])
      }else{
        customerInvoiceSingleData({ _id: newValue?.invoiceId });
      }
    } else if (key === "registerPaymentAmount") {
      if (editedData?.amountDue > value) {
        setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
      } else {
        setFormValues({
          ...formValues,
          [key]: value !== 0 ? editedData?.amountDue : newValue,
        });
      }
    }
  };


  // handleInputChange
  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          setRowIndex(index);
          let customerTax=newValue?.customerTax || []
          let taxObjects=allTaxList?.filter(obj => customerTax.includes(obj._id))

          let selectedTaxType=null
          if(taxObjects.length!==0){
            selectedTaxType=taxObjects[0]?.includedPrice
          }  
          const newFilteredTaxOptions =selectedTaxType !== null? allTaxListSalesType?.filter((option) => option.includedPrice === selectedTaxType)
              : allTaxListSalesType;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            [field]: newValue,
            label: newValue?.productName || "",
            uom: findObjFromList("_id", uomSubList, "uom", newValue),
            qty: newValue !== null ? 1 : "",
            unitPrice: newValue?.salesPrice || "",
            discount: newValue !== null ? 0 : "",
            tax:taxObjects,
            account:
              newValue !== null
                ? findObjFromList(
                    "_id",
                    accountsList,
                    "incomeAccount",
                    configurationList?.sales
                  )
                : null,
            amount:
            newValue !== null ? newValue?.salesPrice: "",
            inclusive: selectedTaxType !== null ? selectedTaxType : "",

          };
        } else if (field === "tax") {
          const selectedTaxId =
            newValue?.length !== 0 ? newValue[0]?.includedPrice : undefined;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allTaxListSalesType.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allTaxListSalesType;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            tax: newValue,
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "qty") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.unitPrice);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "unitPrice") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.qty);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "discount") {
          let totalAmt = 0;
          let totalItemAmt = Number(row?.qty) * Number(row?.unitPrice);
          let discount =
            (Number(value)?.toFixed(decimalPosition) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else {
          return { ...row, [field]: newValue !== undefined ? newValue : e.target.value };
        }
      }
      return row;
    });

    calculateTax(updatedData)
  };

  const calculateTax=(array)=>{
    let taxUpdatedTable=taxCalculationForLines(array)
    setTableData(taxUpdatedTable)
  }


  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, [
      "qty",
      "unitPrice",
      "discount",
      "amount",
    ]);
    setTableData(updatedArray);
  };

  const tableDataToBackend = tableData?.filter((obj) => obj.product !== null).map((item) =>
    item?.type === "lines"
      ? {
          line_type: item?.type,
          _id: item?.product?._id,
          accountId: item?.account?._id,
          mainCatgeoryId: item?.product?.mainCatgeoryId || item?.mainCatgeoryId,
          product: item?.product?.productName,
          label: item?.label,
          uomName: item?.uom?.uomName,
          uom: item?.uom?._id,
          qty: Number(item?.qty),
          unitPrice: Number(item?.unitPrice),
          discount: Number(item?.discount),
          includedPrice: item?.inclusive === "" ? null : item?.inclusive,
          taxes: item?.taxSplitted,
          taxIdList:item?.taxIdList,
          prodType:item?.prodType || item?.product?.prodType
        }
      : {
          line_type: item?.type,
          label: item?.header,
        }
  );

  const formData = new FormData();
  formValues?.employee !== null && formData.append("salesPerson", formValues?.employee?._id)
  /*required*/ formData.append("vendorId", formValues?.customer?._id);
  /*required*/ formData.append("branchId", formValues?.branch?._id);
  formValues?.invoiceReference !== "" &&
    formData.append("invoiceRef", formValues?.invoiceReference);
  formValues?.invoice !== null &&
    formData.append("invoiceId", formValues?.invoice?.invoiceId);
  /*required*/ formData.append("invoiceDate", formValues?.invoiceDate);
  formValues?.paymentReference !== "" &&
    formData.append("paymentRef", formValues?.paymentReference);
  formValues?.accountingDate !== "" &&
    formData.append("accountingDate", formValues?.accountingDate);
  /*required*/ formData.append("journals", formValues?.journal?._id);
  /*required*/ formData.append("currency", formValues?.currency?._id);
  formValues?.paymentTerms !== null && formData.append("paymentTerms", formValues?.paymentTerms?._id)
  /*required*/ formData.append(
    "productInfo",
    JSON.stringify(tableDataToBackend)
  );

  formValues?.termsAndConditions !== "" &&
    formData.append("termsAndCondition", formValues?.termsAndConditions);
  formValues?.customerNotes !== "" &&
    formData.append("customerNotes", formValues?.customerNotes);
  /*required*/ formData.append("postDate", `${today} ${getCurrentTime()}`);
  /*required*/ formData.append(
    "total",
    totalsInfo?.total?.toFixed(decimalPosition)
  );
  formData.append("roundOff",Number(totalsInfo?.roundOff)?.toFixed(decimalPosition));
  const submitBtnClicked = (key) => () => {
    dispatch( setFilterActive(false))
    if (key === "post") {
      let draftFn = () => {
        setFormValues({ ...formValues, isEdit: true, status: "POSTED" });
      };
      let postFormData = new FormData();
      postFormData.append("_id", editDataId);
      postFormData.append("postDate", `${today} ${getCurrentTime()}`);

      postCreditNoteAPICall(
        postFormData,
        draftFn,
        setSnackBarStates,
        snackBarStates,
        setLoadingForm
      );
    }
    if (formValues?.customer === null || formValues?.customer === undefined) {
      setValidationAlert({
        ...validationAlert,
        customer: "fill this field !!",
      });
    }else if (formValues.invoice===null||formValues.invoice===undefined){
      setValidationAlert({
        ...validationAlert,
        invoice: "fill this field !!",
      });
    }
    else if (
      formValues?.branch === null ||
      formValues?.branch === undefined
    ) {
      setValidationAlert({ ...validationAlert, branch: "fill this field !!" });
    } else if (
      formValues?.invoiceDate === "" ||
      formValues?.invoiceDate === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        invoiceDate: "fill this field !!",
      });
    } else if (
      formValues?.accountingDate === "" ||
      formValues?.accountingDate === undefined ||
      formValues?.accountingDate === null
    ) {
      setValidationAlert({
        ...validationAlert,
        accountingDate: "fill this field !!",
      });
    } else if (
      formValues?.journal === null ||
      formValues?.journal === undefined
    ) {
      setValidationAlert({ ...validationAlert, journal: "fill this field !!" });
    } else if (
      formValues?.currency === null ||
      formValues?.currency === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        currency: "fill this field !!",
      });
    } else {
      if (key === "draft") {
        image.length !== 0 &&
          image.forEach((file, index) => {
            formData.append("fileUrl", file);
          });
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
        };
        draftCreditNoteAPICall(
          formData,
          draftFn,
          setSnackBarStates,
          snackBarStates,
          setEditDataId,
          setLoadingForm
        );
      } else if (key === "edit") {
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
          setIsEditClicked(false);
        };
        formData.append("id", editDataId);
        receivedImgUrl?.length !== 0 &&
          formData.append("uploadUrl", JSON.stringify(receivedImgUrl));
        image.forEach((file, index) => {
          formData.append("file", file);
        });

        editCreditNoteAPICall(
          formData,
          draftFn,
          setSnackBarStates,
          snackBarStates,
          setLoadingForm
        );
      } else if (key === "registerPaymentPost") {
        //checking
        const fieldsToCheck = [
          "registerPaymentDate",
          "paymentType",
          "type",
          "chequeNo",
          "registerPaymentChequeDate",
          "registerPaymentAmount",
        ];
        const isFieldEmpty = (field) =>
          field === null || field === undefined || field === "";
        if (singleViewId) {
          let allFieldsValid = true;
          for (const fieldName of fieldsToCheck) {
            if (
              (fieldName === "registerPaymentChequeDate" &&
                formValues.type === "Cheque") ||
              fieldName !== "registerPaymentChequeDate"
            ) {
              if (isFieldEmpty(formValues[fieldName])) {
                setValidationAlert({
                  ...validationAlert,
                  [fieldName]: "fill this field !!",
                });
                allFieldsValid = false;
                break;
              }
            }
          }

          if (allFieldsValid) {
            let sendObj = {
              creditNoteId: singleViewId,
              paymentTypeId: formValues?.paymentType?._id,
              chequeDate: formValues?.registerPaymentChequeDate,
              chequeNo: formValues?.chequeNo,
              type:
                formValues.type == "Manual"
                  ? 1
                  : formValues.type == "Cheque"
                  ? 2
                  : "",
              totalAmount: formValues?.registerPaymentAmount,
              paymentDate: formValues?.registerPaymentDate,
              amountInWords: numToWords(
                Math.floor(formValues?.registerPaymentAmount)
              ),
              postDate: `${today} ${getCurrentTime()}`,
            };

            customerCreditNoteRegisterPaymentPostAPICall(
              sendObj,
              registerPymentCancelBtn,
              setSnackBarStates,
              setLoadingForm
            );
          }
        }
      }
    }
  };
 
  const roundOffOnChange=(e)=>{
    const {value}=e.target
    let roundOffLimit=configurationList?.roundingLimit
    if(value==="-" ||Number(value) >= -(roundOffLimit) && Number(value) <= (roundOffLimit)){
      setTotalsInfo({...totalsInfo,roundOff:value})
    }else{
       dispatch(setSnackMsg(`Maximum allowed round off is :${roundOffLimit}`))
       dispatch(setErrorSnack(true))
    }
  }

  useEffect(() => {
    if (invoiceData !== undefined && allTaxList!==undefined) {
      // setCreditNoteName(invoiceData?.name);
      setFormValues({
        ...formValues,
        branch: allBranchesList.find((res) => res._id === invoiceData.branchId),

        invoiceReference:
          invoiceData?.invoiceReference || invoiceData?.invoiceRef,
        paymentReference:
          invoiceData?.paymentReference || invoiceData?.paymentRef,
        journal: findObjFromList(
          "_id",
          journalSalesList,
          "journals",
          invoiceData
        ),
        currency:
          MultiCurrencyView !== undefined &&
          MultiCurrencyView?.isMultiCurrency === true
            ? findObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                invoiceData
              )
            : findObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                companyProfileData
              ),
        accountingDate: invoiceData?.accountingDate,
        invoiceDate: invoiceData?.invoiceDate,
        paymentTerms:findObjFromList(
          "_id",
          paymentTermsList,
          "paymentTerms",
          invoiceData
        )
      });
      setTotalsInfo({...totalsInfo,roundOff:invoiceData?.rounding?.toFixed(decimalPosition) || 0?.toFixed(decimalPosition)})
      const updatedArray = invoiceData?.productInfo?.map((r, i) =>{
        if(r?.line_type === "Line" || r?.line_type === "lines")
         {  
          let taxesId=r?.taxIdList || []
          let taxObjects = allTaxList?.filter(obj => taxesId.some(taxObj => taxObj._id === obj?._id));

          const newFilteredTaxOptions=r?.includedPrice !== null? allTaxListSalesType?.filter((option) => option.includedPrice === r?.includedPrice)
              : allTaxListSalesType;
          filteredTaxOptions[i] = newFilteredTaxOptions;

          return{
            type: r?.line_type,
            product: findObjFromList("_id", allNewProductList, "_id", r),
            label: r?.label,
            account: findObjFromList(
                "_id",
                chartOfAccountList,
                "accountId",
                r
              ),
              uom: findObjFromList("_id", uomSubList, "uom", r),
              qty: r?.qty,
              unitPrice: r?.unitPrice,
              discount: r?.discount,
              tax: taxObjects,
              amount:
              r?.qty * r?.unitPrice -
              (r?.qty * r?.unitPrice * r?.discount) / 100,
              inclusive: r?.includedPrice,
              prodType:r?.prodType
            }
          }
          else {
            return{
              type: r?.line_type,
              header: r?.label,
            }
            }
          }
      );
      calculateTax(updatedArray)
    } else {
      clearForm();
    }
  }, [invoiceData, allBranchesList,allTaxList]);

  useEffect(() => {

    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
    journalListApiCall();
    viewMultiCurrencyConfiguration();
    chartOfAccountListApiCall();
    getAllTaxSettingsListAPICall();
    viewUOMSubListAPICall();
    
    if(singleRowId){
      crediNotesSingleViewAPICall({ _id: singleRowId });
    }
  }, []);

  useEffect(() => {

    let subTotal=tableData?.reduce((sum, item) => sum + Number(item?.amount || 0),0)
    let inclusiveTax=tableData?.reduce((sum, item) => sum + Number(item?.inclusiveTax || 0),0)
    let exclusiveTax=tableData?.reduce((sum, item) => sum + Number(item?.exclusiveTax || 0),0)
    let roundedValue=Number(totalsInfo.roundOff)?.toFixed(decimalPosition)
    setTotalsInfo({
      ...totalsInfo,
      subTotal:subTotal,
      inclusiveTax:inclusiveTax,
      exclusiveTax:exclusiveTax,
      total:subTotal+exclusiveTax+Number(roundedValue)
    });

  }, [tableData,totalsInfo.roundOff]);

  useEffect(() => {
    if (invoiceData === undefined) {
      editedData === undefined &&
        generateCreditNoteNameAPICall({
          branchId: formValues?.branch?._id,
          invoiceDate: formValues?.invoiceDate,
        },setCreditNoteName);
    }
  }, [formValues?.invoiceDate, formValues?.branch]);


  // edit
  useEffect(() => {
    if (editedData !== undefined ) {

      setEditDataId(editedData?._id);
      setCreditNoteName(editedData?.seqName);
      setFormValues({
        ...formValues,
        isEdit: true,
        status: editedData?.status,

        customer:editedData?.vendorId,
        invoice: invoiceList?.find(
          (data) => data.invoiceId === editedData.invoiceId
        ),
        branch: allBranchesList?.find(
          (data) => data._id === editedData.branchId
        ),
        invoiceReference: editedData?.invoiceRef,
        paymentReference: editedData?.paymentRef,
        employee: findObjFromList(
          "_id",
          allActiveEmpList,
          "salesPerson",
          editedData
        ),
        journal: findObjFromList(
          "_id",
          journalSalesList,
          "journals",
          editedData
        ),
        currency: findObjFromList(
          "_id",
          currencyListUpdated,
          "currency",
          editedData
        ),
        accountingDate: editedData?.accountingDate,
        invoiceDate: editedData?.invoiceDate,
        customerNotes: editedData?.customerNotes,
        termsAndConditions: editedData?.termsAndCondition,
        amountDue: editedData?.amountDue,
        paymentsDone: editedData?.paymentsDone,
        paymentStatus: editedData?.paymentStatus,
        registerPaymentAmount: editedData?.amountDue,
      });
      setTotalsInfo({...totalsInfo,roundOff:editedData?.rounding?.toFixed(decimalPosition) || 0?.toFixed(decimalPosition)})
      setImageDisplay(
        editedData?.fileUrl?.map((r) => {
          return {
            url: r.url,
          };
        })
      );
      setReceivedImgUrl(
        editedData?.fileUrl?.map((r) => {
          return {
            url: r?.url,
            _id: r?._id,
          };
        }) || []
      );
      setLog(editedData?.log);
      invoiceList===undefined && getInvoicesListOfCustomerAPI({ customerId: editedData?.vendorId });
    } else {
      clearForm();
    }
  }, [editedData, allBranchesList, currencyListUpdated,userDetails]);
  

  useEffect(() => {
    if (editedData?.productInfo !== undefined && allTaxList!==undefined) {
      const updatedArray = editedData?.productInfo?.map((r, i) => {
        if (r?.line_type === "Line" || r?.line_type === "lines") {
          let taxesId=r?.taxIdList || []
          let taxObjects = allTaxList?.filter(obj => taxesId.some(taxObj => taxObj._id === obj?._id));

          const newFilteredTaxOptions=r?.includedPrice !== null? allTaxListSalesType?.filter((option) => option.includedPrice === r?.includedPrice)
              : allTaxListSalesType;
          filteredTaxOptions[i] = newFilteredTaxOptions;
          return {
            type: r?.line_type,
            product: r?.prodObj,
            label: r?.label,
            account: findObjFromList(
              "_id",
              chartOfAccountList,
              "accountId",
              r
            ),
            uom: findObjFromList("_id", uomSubList, "uom", r),
            qty: r?.qty,
            unitPrice: r?.unitPrice,
            discount: r?.discount,
            tax:taxObjects,
            amount:
              r?.qty * r?.unitPrice -
              (r?.qty * r?.unitPrice * r?.discount) / 100,
            inclusive: r?.includedPrice,
            prodType:r?.prodType
          };
        } else {
          return {
            type: r?.line_type,
            header: r?.label,
          };
        }
      });

      calculateTax(updatedArray)
    }
  }, [
    editedData?.productInfo,
    uomSubList,
    chartOfAccountList,allTaxList
  ]);



  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formValues?.isEdit]);

  //clearRegisterForm
  const clearRegisterForm = () => {
    setFormValues({
      ...formValues,
      registerPaymentDate: "",
      paymentType: null,
      type: null,
      chequeNo: "",
      registerPaymentChequeDate: "",
      registerPaymentAmount: "",
    });
  };

  //cancel registered payment
  const registerPymentCancelBtn = () => {
    setRegisterPayment(false);
    clearRegisterForm();
  };
  // filter payment type
  useEffect(() => {
    if (journalList !== undefined) {
      let stockValuationAccList = journalList?.filter(
        (item) => item.type === "bank" || item.type === "cash"
      );
      setPaymentTypeList(stockValuationAccList);
    }
  }, [journalList]);

  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 0", backgroundColor: "unset" }}
      >
        <div className="new-global-white-bg-icon-container" style={{backgroundColor:"white"}}>
          {isLoading ? (
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={40}
            />
          ) : (
            <div className="new-global-white-bg-icon-container-left" style={{ display: "flex", alignItems: "center" }}>
              {/* <Tooltip
                title="Back"
                onClick={() => {
                  if (formValues?.isEdit === false) {
                    const userConfirmed = window.confirm(
                      "Changes you made may not be saved."
                    );

                    if (userConfirmed) {
                      navigateTo(
                        "/userdashboard/accounts/customers/creditNotes"
                      );
                      dispatch(set_Back_button_action({ backButtonClickAction: true }));
                    }
                  } else {
                    navigateTo("/userdashboard/accounts/customers/creditNotes");
                    dispatch(set_Back_button_action({ backButtonClickAction: true }));
                  }
                }}
              >
                <IconButton>
                  <i class="bi bi-chevron-left"></i>
                </IconButton>
              </Tooltip> */}
              {formValues?.status === "DRAFT" && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={
                      editClicked
                        ? submitBtnClicked("edit")
                        : () => {
                            setFormValues({ ...formValues, isEdit: false });
                            setIsEditClicked(true);
                          }
                    }
                    onMouseEnter={handleMouseEnter}
                  >
                    <i
                      class={
                        editClicked
                          ? "bi bi-floppy save-icon"
                          : "bi bi-pencil-square edit-icon1"
                      }
                    ></i>
                    {/* <i class="bi bi-check2-square edit-icon"></i> */}
                  </IconButton>
                </Tooltip>
              )}

              {formValues?.status === "" && (
                <Tooltip title="Save">
                  <IconButton
                    onMouseEnter={handleMouseEnter}
                    onClick={submitBtnClicked("draft")}
                  >
                    <i class="bi bi-floppy save-icon"></i>
                  </IconButton>
                </Tooltip>
              )}

              {formValues?.status === "DRAFT" && !editClicked && (
                <button
                  onClick={submitBtnClicked("post")}
                  className="btn btn-post"
                >
                  Post
                </button>
              )}
              {formValues?.status === "COMPLETED" &&
                formValues?.paymentStatus === false && (
                  <button
                    onClick={() => setRegisterPayment(true)}
                    className="btn btn-post"
                  >
                    Register Payment
                  </button>
                )}
        <h4 className="global-name">
        <span
                style={{ fontSize: "medium",  color: "#676666", cursor: "pointer" }}

                onClick={() => {
                  if (formValues?.isEdit === false) {
                    const userConfirmed = window.confirm(
                      "Changes you made may not be saved."
                    );

                    if (userConfirmed) {
                      navigateTo(
                        "/userdashboard/accounts/customers/creditNotes"
                      );
                      dispatch(set_Back_button_action({ backButtonClickAction: true }));
                    }
                  } else {
                    navigateTo("/userdashboard/accounts/customers/creditNotes");
                    dispatch(set_Back_button_action({ backButtonClickAction: true }));
                  }
                }}
              >
                Purchase Order &gt;
              </span>
              <span style={{color:"black",fontSize:"medium"}}>
          {!isLoading && creditNoteName}</span></h4>

            </div>
          )}
        
        </div>
        {/* <hr className="global-hr" /> */}
        <div style={{backgroundColor:"white",padding:"10px",marginTop:"6px",position:"relative"}}>

        <div className="status-container" style={{right:"0"}}>
            {!isLoading && formValues?.status === "DRAFT" && (
              <img src={draftRibbon} alt="" />
            )}
            {!isLoading && formValues?.status === "COMPLETED" && (
              <img src={postRibbon} alt="" />
            )}
          </div>
        {isLoading ? (
          <Skeleton
            sx={{ margin: "1% 0" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={150}
          />
        ) : (
          <div className="new-global-input-container">
            <div className="new-global-single-input ">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerList || []}
                filterOptions={customerFilterOptions}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer*"
                    focused
                  />
                )}
                onChange={getFormInfo("customer")}
                value={formValues?.customer}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.customer}
              </p>
            </div>
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={invoiceList || []}
                getOptionLabel={(option) => option?.invoiceNo}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Invoice" focused />
                )}
                onChange={getFormInfo("invoice")}
                value={formValues.invoice}
                disabled={formValues?.isEdit}
              />
               <p className="doc-validation-alert">
                {validationAlert?.invoice}
              </p>
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allBranchesList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                isOptionEqualToValue={(option, value) => option._id === value._id}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch*" focused />
                )}
                onChange={getFormInfo("branch")}
                value={formValues?.branch}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.branch}</p>
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allActiveEmpList || []}
                getOptionLabel={(option) => 
                  option?.staff_name
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Sales Person" focused />
                )}
                onChange={getFormInfo("employee")}
                value={formValues?.employee}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.employee}</p>
            </div>

            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Invoice Refrence"
                variant="outlined"
                type="text"
                focused
                value={formValues?.invoiceReference}
                onChange={getFormInfo("invoiceReference")}
                disabled={formValues?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.invoiceDate}
                onChange={getFormInfo("invoiceDate")}
                id="outlined-basic"
                label="Invoice Date*"
                variant="outlined"
                type="date"
                focused
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.invoiceDate}
              </p>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.paymentReference}
                onChange={getFormInfo("paymentReference")}
                id="outlined-basic"
                label="Payment Reference"
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.accountingDate || ""}
                onChange={getFormInfo("accountingDate")}
                id="outlined-basic"
                label="Accounting Date"
                variant="outlined"
                type="date"
                focused
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.accountingDate}
              </p>
            </div>
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={journalSalesList || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Journal*" focused />
                )}
                value={formValues?.journal}
                onChange={getFormInfo("journal")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.journal}</p>
            </div>
            <div className="new-global-single-input">
              {MultiCurrencyView !== undefined &&
              MultiCurrencyView?.isMultiCurrency ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={currencyListUpdated || []}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Currency*" focused />
                  )}
                  value={formValues?.currency}
                  onChange={getFormInfo("currency")}
                  disabled={formValues?.isEdit}
                />
              ) : (
                <TextField
                  value={formValues?.currency?.name || ""}
                  sx={{ caretColor: "transparent" }}
                  className="disabled-input"
                  id="outlined-basic"
                  label="Currency*"
                  variant="outlined"
                  type="text"
                  focused
                  title="Multi Currency not enabled"
                  disabled={formValues?.isEdit}
                />
              )}
              <p className="doc-validation-alert">
                {validationAlert?.currency}
              </p>
            </div>
            <div
              className="new-global-single-input "
              style={{ width: "22%" }}
            >
              <div className="" style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={paymentTermsList || []}
                  getOptionLabel={(option) => option?.paymentTerm}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Terms*" focused />
                  )}
                  value={formValues?.paymentTerms || null}
                  onChange={getFormInfo("paymentTerms")}
                  disabled={formValues?.isEdit}
                />
                {!formValues.isEdit && (
                  <button
                    onClick={() => {
                      setFormValues((prev) => ({
                        ...prev,
                        paymentTerms: null,
                      }));
                      setHandlePaymentTermsbtn(!handlePaymentTermsbtn);
                    }}
                    className="add-row-btn autocomplete-inside-plusbtn"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
           
          </div>
        )}
      </div>

      <div className="new-global-white-bg-container">
        {isLoading ? (
          <Skeleton
            sx={{ marginTop: "1%" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={110}
          />
        ) : (
          <>
            <div
              className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
              style={{ marginBottom: "1%" }}
            >
              <div
                className="tab-menu-button active"
                id="productMenuBtn"
                onClick={productMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Invoice Lines
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="departmentMenuBtn"
                onClick={departmentMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Other Info
                </Typography>
              </div>
            </div>
            {isActiveTable === "invoiceLines" ? (
              <>
               <TableVirtuoso
                    ref={containerRef}
                    className="global-product-table"
                    style={{ height: tableData?.length === 1 ? "10vh" : tableData?.length < 5 ? `${(tableData?.length) * 7}vh` : "25vh", maxHeight: "35vh" }}
                    data={tableData}
                    fixedHeaderContent={() => (
                      <tr>
                        <th>Product</th>
                        <th>Label</th>
                        {!formValues.invoice && <th>Account</th>}
                        <th>UOM</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Discount</th>
                        <th>Tax</th>
                        <th>Amount</th>
                        <th style={{ backgroundColor: "#fff" }}></th>
                      </tr>
                    )}
              
                    itemContent={(index, item) => (
                        <>
                          {item.type === "Header" ? (
                            <>
                              <td colSpan="9">
                                <input
                                  type="text"
                                  value={item.header}
                                  onChange={handleInputChange(index, "header")}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                  disabled={formValues?.isEdit}
                                />
                              </td>
                              <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                  style={{ height: "30px" }}
                                  disabled={formValues?.isEdit}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>
                            </>
                          ) : (
                            <>
                              <td style={{ minWidth: "150px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={allNewProductList || []}
                                    style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option)=>option?.productName}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseEnter}
                                      />
                                    )}
                                    disabled={formValues?.isEdit}
                                    value={item?.product}
                                    onChange={handleInputChange(
                                      index,
                                      "product"
                                    )}
                                  />
                                </div>
                              </td>
                              <td style={{  width:`${
                                    String(item?.label).length * 10
                                  }px`,minWidth:"90px" }}>
                                <input
                                  type="text"
                                  onChange={handleInputChange(index, "label")}
                                  value={item?.label}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                />
                              </td>
                              {!formValues.invoice && (
                                <td style={{ minWidth: "100px" }}>
                                  <div className="product-table-auto-complete">
                                    <Autocomplete
                                      options={chartOfAccountList || []}
                                      style={{ maxHeight: "100px" }}
                                      getOptionLabel={(option) =>
                                        option?.accountName
                                      }
                                      disabled={formValues?.isEdit}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          onMouseEnter={handleMouseEnter}
                                          onMouseLeave={handleMouseEnter}
                                        />
                                      )}
                                      value={item?.account}
                                      onChange={handleInputChange(
                                        index,
                                        "account"
                                      )}
                                    />
                                  </div>
                                </td>
                              )}
                              <td style={{ minWidth: "50px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={uomSubList?.filter((obj)=>obj?.parentCatId === (item?.product?.parentUomId)) || []}
                                    className="uom-autocomplete"
                                    style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option) => option?.uomName}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    disabled={formValues?.isEdit}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseEnter}
                                      />
                                    )}
                                    value={item?.uom}
                                    onChange={handleInputChange(index, "uom")}
                                  />
                                </div>
                              </td>
                              <td style={{ width:`${
                                    String(item?.qty).length * 9
                                  }px`,minWidth:"40px"}}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  onChange={handleInputChange(index, "qty")}
                                  value={item?.qty}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                  onFocus={(e)=>e.target.select()}
                                />
                              </td>
                              <td style={{ maxWidth: "90px" }}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  onChange={handleInputChange(
                                    index,
                                    "unitPrice"
                                  )}
                                  value={item?.unitPrice}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                  onFocus={(e)=>e.target.select()}
                                />
                              </td>
                              <td style={{ maxWidth: "30px" }}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  onChange={handleInputChange(
                                    index,
                                    "discount"
                                  )}
                                  value={item?.discount}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                  onFocus={(e)=>e.target.select()}
                                />
                              </td>
                              <td style={{maxWidth:"245px",minWidth:item?.tax?.length===0?"90px":item?.tax?.length===1?"200px":`265px`}}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    multiple
                                    className="multiple-tax-autocomplete"
                                    options={filteredTaxOptions[index] || []}
                                    getOptionLabel={(option) => option?.taxName}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                      />
                                    )}
                                    value={item.tax}
                                    onChange={handleInputChange(index, "tax")}
                                    disabled={formValues?.isEdit}
                                  />
                                </div>
                              </td>
                              <td style={{ width:`${
                                    String(item?.amount).length * 9
                                  }px`,minWidth:"40px"}}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  readOnly
                                  value={item?.amount}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                />
                              </td>
                              {
                                (formValues?.status === "" || editClicked )&&
                              <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                  style={{ height: "30px" }}
                                  disabled={formValues?.isEdit}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>}
                            </>
                          )}
                        </>
                      )}
                    />
               {
                  (formValues?.status === "" || editClicked )&&
                  <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      disabled={formValues?.isEdit}
                    />
                  </div>
                  <button
                    disabled={formValues?.isEdit}
                    onClick={() => addRow()}
                    className="add-row-btn"
                  >
                    +
                  </button>
                </div>}
              </>
            ) : (
              <div
                className="new-global-input-container"
                style={{ flexDirection: "column" }}
              >
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Order Date*"
                    variant="outlined"
                    type="date"
                    focused
                    disabled={formValues?.isEdit}
                    value={formValues?.orderDate}
                    onChange={getFormInfo("orderDate")}
                  />
                </div>

                <div className="global-product-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Account</th>
                        <th>Partner</th>
                        <th>Employee</th>
                        <th>Branch</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ border: "0" }} colSpan={3}></td>
                        <td style={{ border: "0", textAlign: "end" }}>
                          Total :
                        </td>
                        <td style={{ border: "0", textAlign: "start" }}>
                          0.00
                        </td>
                        <td style={{ border: "0", textAlign: "start" }}>
                          0.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {isActiveTable === "invoiceLines" && (
        <div
          className="new-global-white-bg-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "8px 2%",
          }}
        >
          {isLoading ? (
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={110}
            />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "97%",
                }}
              >
                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Customer Notes</p>
                  <textarea
                    value={formValues?.customerNotes}
                    onChange={getFormInfo("customerNotes")}
                    disabled={formValues?.isEdit}
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Terms & Conditions</p>
                  <textarea
                    value={formValues?.termsAndConditions}
                    onChange={getFormInfo("termsAndConditions")}
                    disabled={formValues?.isEdit}
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ maxWidth: "250px" }}
                  onMouseLeave={()=>setTotalsInfo({...totalsInfo,roundOff:Number(totalsInfo?.roundOff)?.toFixed(decimalPosition)})}
                >
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p>Sub Total</p>
                      {totalsInfo?.inclusiveTax !== 0 && <p>Inclusive Tax</p>}

                      {totalsInfo?.exclusiveTax !== 0 && <p>Exclusive Tax</p>}
                      {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>Tax</p>}
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{totalsInfo?.subTotal?.toFixed(decimalPosition)}</p>
                      {totalsInfo?.inclusiveTax !== 0 && (
                        <p>
                          {totalsInfo?.inclusiveTax?.toFixed(decimalPosition)}
                        </p>
                      )}
                      {totalsInfo?.exclusiveTax !== 0 && (
                        <p>
                          {totalsInfo?.exclusiveTax?.toFixed(decimalPosition)}
                        </p>
                      )}
                      {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>0.00</p>}
                    </div>
                  </div>
                  <hr
                    className="global-hr"
                    style={{ width: "80%", marginLeft: "25%" }}
                  />
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p style={{ color: "#000", fontWeight: "600" }}>Total</p>
                     {
                     configurationList?.isRoundingEnabled &&
                     <p >Round Off</p>}
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{totalsInfo?.total?.toFixed(decimalPosition)} </p>
                      {
                      configurationList?.isRoundingEnabled &&  
                      <input 
                      type="number"
                      onFocus={(e)=>e.target.select()}
                      value={totalsInfo?.roundOff}
                      disabled={formValues?.isEdit}
                      onChange={roundOffOnChange}
                      onMouseLeave={()=>setTotalsInfo({...totalsInfo,roundOff:Number(totalsInfo?.roundOff)?.toFixed(decimalPosition)})}
                      
                      />}
                    </div>
                  </div>
                  <div className="balanceAmountdiv">
                    {formValues?.paymentsDone &&
                      formValues?.paymentsDone.map((res, index) => (
                        <div className="paydiv" style={{ display: "flex" }}>
                          <p>{res?.name}</p>
                          <p>
                            {formValues?.currencyDetails?.symbol}
                            {res?.totalAmount}
                          </p>
                        </div>
                      ))}

                    {formValues?.status === "COMPLETED" &&
                      !formValues?.paymentStatus && (
                        <div className="paydiv">
                          <p>AmountDue</p>
                          <p> {formValues?.currencyDetails?.symbol}</p>
                          <p>{formValues?.amountDue}</p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <div className="file-picker-new">
                  <input
                    disabled={formValues?.isEdit}
                    type="file"
                    id="fileUpload"
                    onChange={changeImage}
                  />
                  <label htmlFor="fileUpload">
                    <img src={uploadFile} alt="" />
                    Upload File
                  </label>
                </div>
                <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                  {imageDisplay?.map((r, i) => (
                    <div className="uploaded-image-list">
                      <img src={r?.url} alt="" />
                      <div className="image-remove-icon-new">
                        <IconButton
                          disabled={formValues?.isEdit}
                          onClick={() => removeImageFn(i)}
                        >
                          <CloseOutlined
                            sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {editedData !== undefined && (
        <div
          className="new-global-white-bg-container new-log-container"
          style={{ padding: "4px" }}
        >
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={clickLogView}
          ></div>
          <div
            className={logView ? "pointer-up" : "pointer"}
            onClick={clickLogView}
            style={{ cursor: "pointer" }}
          ></div>
          {logView && (
            <div className="single-log-container">
              {log?.map((r, i) => (
                <div className="single-log-item">
                  <div className="single-log-item-line-image">
                    <hr className="single-log-item-line" />
                    <div className="single-log-item-image">
                      <img src={logoImage} alt="" />
                    </div>
                    <hr className="single-log-item-line" />
                  </div>
                  <p className="heading">{r?.status}</p>
                  <p className="name">{r?.empId}</p>
                  <p className="date-time">{r?.date}</p>
                  <p className="date-time">{r?.time}</p>
                </div>
              ))}
            </div>
          )}

          {/* <div className='single-log-container-scroll-line'></div> */}
        </div>
      )}
      {/* register Payment dialog */}
      <Dialog
        maxWidth="md"
        open={registerPayment}
        onClose={() => setRegisterPayment(false)}
      >
        <div className="payment-popup">
          <h4>Register Payment</h4>
          <hr className="global-hr" />

          <div className="global-input-field-container">
            <div
              className="new-global-single-input"
              style={{ width: "47%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Date*"
                variant="outlined"
                type="date"
                focused
                value={formValues?.registerPaymentDate}
                onChange={getFormInfo("registerPaymentDate")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.registerPaymentDate}
              </p>
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "45%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={paymentTypeList || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Type*" focused />
                )}
                value={formValues?.paymentType}
                onChange={getFormInfo("paymentType")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentType}
              </p>
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "47%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["Manual", "Cheque"]}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Type*" focused />
                )}
                value={formValues?.type}
                onChange={getFormInfo("type")}
              />
              <p className="doc-validation-alert">{validationAlert?.type}</p>
            </div>
            <div
              className="new-global-single-input"
              style={{ width: "45%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label={
                  formValues?.type === "Cheque"
                    ? "Cheque No."
                    : "Transaction No"
                }
                variant="outlined"
                type="text"
                focused
                value={formValues?.chequeNo}
                onChange={getFormInfo("chequeNo")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.chequeNo}
              </p>
            </div>
            {formValues?.type === "Cheque" && (
              <>
                <div
                  className="new-global-single-input"
                  style={{ width: "47%", padding: "6px" }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Cheque Date*"
                    variant="outlined"
                    type="date"
                    focused
                    value={formValues?.registerPaymentChequeDate}
                    onChange={getFormInfo("registerPaymentChequeDate")}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.registerPaymentChequeDate}
                  </p>
                </div>
              </>
            )}

            <div
              className="new-global-single-input"
              style={{ width: `${formValues?.type === "Cheque"?"45%":"47%"}`, padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Amount*"
                variant="outlined"
                type="number"
                focused
                value={formValues?.registerPaymentAmount}
                onChange={getFormInfo("registerPaymentAmount")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.registerPaymentAmount}
              </p>
            </div>
          </div>
          <hr className="global-hr" />
          <div>
            <button
              className="btn btn-primary"
              onClick={submitBtnClicked("registerPaymentPost")}
            >
              Post
            </button>
            <button
              className="btn btn-secondary-outlined"
              onClick={registerPymentCancelBtn}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <PaymentTerms handleDialog={handlePaymentTermsbtn} setHandleDialog={setHandlePaymentTermsbtn} />
      <LoadingForm loading={loadingForm} />
    </div>
    </div>
  );
};
