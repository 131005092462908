import React, { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  convertArrayToExcel,
  filterObjFromList,
} from "../../../../../Js/generalFunctions";
import { useEffect } from "react";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { BankAndCashReportSingleAPIcall } from "./GeneralReportAPI";
import { IconButton, Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";
import Template from "./BankAndCashSinglePrint";
import PrintComponent from "../../../../print/report/PrintComponent";
import ExcelJS from "exceljs"

export const BankAndCashReportSingleView = () => {
  let paymentsPrintNew = useRef();
 

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );

  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const account = localStorage.getItem("account");
  const singleViewId = localStorage.getItem("singleViewById");
  // const StartDate = localStorage.getItem("fromDate");
  // const EndDate = localStorage.getItem("toDate");
  const selectedFromDate = localStorage.getItem("selectedFromDate");
  const selectedToDate = localStorage.getItem("selectedToDate");

  const decimalPosition = localStorage.getItem("decimalPosition");

  const singleView = useSelector(
    (state) => state?.generalReport?.BankAndCashSingleList
  );
  // console.log(singleView);

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const handleback = () => {
    navigate("/userdashboard/accounts/report/generalReport/bankAndCashReport");
  };

  const initialvalue = {
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,
    label: "Bank and Cash",

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  };
  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(initialvalue);

  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Bank and Cash");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Bank and Cash"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:F1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    // const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    // if (Array.isArray(filterParts)) {
    //   filterParts.forEach((part, index) => {
    //     const row = sheet.addRow([part]);
    //     row.font = { size: 15, bold: true };
    //     sheet.mergeCells(`A${index + 2}:D${index + 2}`);
    //     row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    //   });
    // }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Journal",
      "Referance",
      "Debit",
      "Credit",
      "Balance",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "date", width: 50 },
      { header: "Journal", key: "journalNo", width: 20 },
      { header: "Referance", key: "reference", width: 20 },
      { header: "Debit", key: "debit", width: 20 },
      { header: "Credit", key: "credit", width: 20 },
      { header: "Bank and Cash", key: "balance", width: 20 },
    ];
    singleView?.entries?.forEach((item) => {
      const row = sheet.addRow({
        date: item?.date,
        journalNo: item.journalNo,
        reference: item.reference,
        debit:
          typeof item?.debit === "number"
            ? `${symbol} ${item.debit.toFixed(2)}`
            : "",
        credit:
          typeof item?.credit === "number"
            ? `${symbol} ${item.credit.toFixed(2)}`
            : "",
        balance:
          typeof item?.balance === "number"
            ? `${symbol} ${item.balance.toFixed(2)}`
            : "",
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFC3D4F7" } },
          left: { style: "thin", color: { argb: "FFC3D4F7" } },
          bottom: { style: "thin", color: { argb: "FFC3D4F7" } },
          right: { style: "thin", color: { argb: "FFC3D4F7" } },
        };
        if (colNumber === 4) {
          // Assuming the balance column is the 4th column
          const value = parseFloat(item?.balance);
          if (value < 0) {
            cell.font = { color: { argb: "FFFF0000" } }; // Red color for negative values
          }
        }
      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      `${symbol} ${singleView?.totalDebit?.toFixed(2)}`,
      `${symbol} ${singleView?.totalCredit?.toFixed(2)}`,
      `${symbol} ${singleView?.totalBalance?.toFixed(2)}`,
    ]);

    totalRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Bank and Cash.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    setFormValues({
      ...formValues,
      totalBalance: singleView?.totalBalance || 0,
      totalCredit: singleView?.totalCredit || 0,
      totalDebit: singleView?.totalDebit || 0,

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
    });
    setData(singleView?.entries);
  }, [singleView, companyProfileData]);

  useEffect(() => {
    if (configurationList !== undefined) {
      setToDate(configurationList?.yearEndDate);
      setFromDate(configurationList?.yearStartDate);
    }
  }, [configurationList]);
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  useEffect(() => {
    if (toDate !== undefined && fromDate !== undefined) {
      const startDate = fromDate === "" ? "" : `${fromDate} 00:00:00`;
      const endDate = toDate === "" ? "" : `${toDate} 23:59:59`;
      BankAndCashReportSingleAPIcall({
        startDate:
          selectedFromDate === "" ? startDate : `${selectedFromDate} 00:00:00`,
        endDate: selectedToDate === "" ? endDate : `${selectedToDate} 23:59:59`,
        id: singleViewId,
      });
    }
  }, [fromDate, toDate, selectedFromDate, selectedToDate, singleViewId]);

  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div
        className="global-white-bg-container"
        style={{ backgroundColor: "unset", padding: "4px" }}
      >
        <div
          style={{
            backgroundColor: "white",
            paddingTop: "8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="sharp-arrow" onClick={() => handleback()}>
              {" "}
              <p style={{ margin: "0" }}>Bank & Cash &gt;</p>
            </div>
            <div className="sharp-arrow" style={{ width: "auto" }}>
              {" "}
              <p style={{ margin: "0" }}>{account}</p>
            </div>
          </div>
          <div style={{ paddingInline: "15px", marginTop: "-11px" }}>
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i
                    style={{
                      color: "white",
                      backgroundColor: " #d787e7",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                    class="bi bi-printer"
                  ></i>
                </IconButton>
              )}
              content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          className="global-report-table-container"
          style={{
            maxHeight: "84vh",
            height: "84vh",
            backgroundColor: "white",
            marginTop: "3px",
          }}
        >
          <table style={{ padding: "4px" }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Journal</th>
                <th>Referance</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {singleView?.entries?.map((item, index) => (
                <tr key={index} style={{ height: "38px" }}>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.date}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.journalNo}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.reference}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {symbol}&nbsp;{item.debit?.toFixed(decimalPosition)}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {symbol}&nbsp;{item.credit?.toFixed(decimalPosition)}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {symbol}&nbsp;{item.balance?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="3">Total</th>
                <th>
                  {symbol}&nbsp;
                  {singleView?.totalDebit?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {singleView?.totalCredit?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {singleView?.totalBalance?.toFixed(decimalPosition)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <PrintComponent
            header={"Bank & Cash Report"}
            subHead={account}
            pageHeight={80}
          >
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Journal</th>
                  <th>Referance</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {singleView?.entries?.map((item, index) => (
                  <>
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.journalNo}</td>
                      <td>{item.reference}</td>
                      <td>
                        {symbol}&nbsp;{item.debit?.toFixed(decimalPosition)}
                      </td>
                      <td>
                        {symbol}&nbsp;{item.credit?.toFixed(decimalPosition)}
                      </td>
                      <td>
                        {symbol}&nbsp;{item.balance?.toFixed(decimalPosition)}
                      </td>
                    </tr>
                    {singleView?.entries?.length - 1 === index && (
                      <tr>
                        <th colSpan="3">Total</th>
                        <th>
                          {symbol}&nbsp;
                          {singleView?.totalDebit?.toFixed(decimalPosition)}
                        </th>
                        <th>
                          {symbol}&nbsp;
                          {singleView?.totalCredit?.toFixed(decimalPosition)}
                        </th>
                        <th>
                          {symbol}&nbsp;
                          {singleView?.totalBalance?.toFixed(decimalPosition)}
                        </th>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </PrintComponent>
          {/* <Template  data={data} formValues={formValues}/>  */}
        </div>
      </div>
    </div>
  );
};

export default BankAndCashReportSingleView;
