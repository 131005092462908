import React, { useEffect, useRef, useState } from 'react'
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StockMoveSingleAPIcall } from './ReportAPI';
import Template from "./StockMoveSinglePrint"
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import * as XLSX from "xlsx";
import ExcelJS from "exceljs"


export const StockMoveSingleView = () => {
  let paymentsPrintNew = useRef();

  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);
  const [filteredData, setFilteredData] = useState({});

  // const singleView = useSelector(
  //   (state) => state?.Report?.StockMoveSingle
  // );
 
  const {tableLoading} = useSelector((state) => state.mainDashBoardSlice);

  const singleView = useSelector((state) => state.ReportSlice.StockMoveSingle)


  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const branch = localStorage.getItem("branch")
  const location = localStorage.getItem("location")
  const FromDate = localStorage.getItem("fromDate")
  const ToDate = localStorage.getItem("ToDate")
  const Stock_id = localStorage.getItem("stock_id")
  const vendor = localStorage.getItem("vendor")

  const [unitCost,setUnitCost]=useState(0)
  const handleback = () => {

    navigate("/userdashboard/inventory/reports/stockMove")
  }
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  const initialvalue = {
    label: "Stock Move",
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  }

  const [data, setData] = useState([])
  const [formValues, setFormValues] = useState(initialvalue)

  const [lineTotals,setLineTotals]=useState({
    totalIn:0,
    totalOut:0,
    totalClosing:0,
    totalValue:0
  })
  useEffect(() => {
    setFormValues({
      ...formValues,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",

    });
    setData(singleView)
    if(singleView.length!==0){
      setUnitCost(singleView[0]?.cost)

      let inQuantityTotal=singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.inQuantity, 0)?.toFixed(decimalPositionStock)
      let openingBalance=singleView[0]?.openingBalance?.toFixed(decimalPositionStock)
      let closingBalance=singleView[0]?.closingBalance?.toFixed(decimalPositionStock)
      let outQuantityTotal=singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.outQuantity, 0)?.toFixed(decimalPositionStock)
      let totalValue=(Number(closingBalance)*singleView[0]?.cost)?.toFixed(decimalPosition)
      setLineTotals({...lineTotals,
        totalIn:(Number(inQuantityTotal)+Number(openingBalance))?.toFixed(decimalPosition),
        totalOut:outQuantityTotal,
        totalClosing:(Number(inQuantityTotal)-Number(outQuantityTotal)+Number(openingBalance))?.toFixed(decimalPosition),
        totalValue
      })
    }
  }, [singleView, companyProfileData])

  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }

    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
    if (filters?.location) {
      parts.push(`Branch: ${filters?.location?.name}`);
    }
   
    
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Stock Move Report");
    sheet.properties.defaultRowHeight = 20;
  
    // Title Section
    const titleRow = sheet.addRow(["Stock Move Report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
  
    // Filter Information Section
    const filterParts = formatFilterInfo(filteredData) || [];
    filterParts.forEach((part, index) => {
      const row = sheet.addRow([part]);
      row.font = { size: 14, bold: true };
      sheet.mergeCells(`A${index + 2}:F${index + 2}`);
      row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
    });
  
    // Add an empty row after the filters
    sheet.addRow([]);
  
    // Table Header
    const headerRow = sheet.addRow(["Date", "Particular", "In", "Out", "Closing", "Value"]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF1d1da7" }, // Dark blue
      };
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White text
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = { bottom: { style: "thin" } };
    });
  
    // Populate Data Rows
    if (singleView && singleView.length > 0) {
      const { stockMoves = [], openingBalance, cost } = singleView[0];
      const openingValue = (Number(openingBalance) * Number(cost)).toFixed(decimalPosition);
  
      // Add opening balance row
      sheet.addRow([
        "", // Date
        "Opening Balance", // Particular
        openingBalance?.toFixed(decimalPositionStock), // In
        "0.00", // Out
        openingBalance?.toFixed(decimalPositionStock), // Closing
       
        symbol + " " + openingValue, // Value
      ]);
  
      // Add stock move rows
      stockMoves.forEach((move) => {
        sheet.addRow([
          move.convertedDate, // Date
          move.reference, // Particular
          move.inQuantity?.toFixed(decimalPositionStock), // In
          move.outQuantity?.toFixed(decimalPositionStock), // Out
          move.closingBalance?.toFixed(decimalPositionStock), // Closing
         
          symbol + " " + move.value?.toFixed(decimalPosition), // Value
        ]);
      });
  
      // Add Total Row
      sheet.addRow([
        "Total", // Date
        "", // Particular
        lineTotals.totalIn, // Total In
        lineTotals.totalOut, // Total Out
        lineTotals.totalClosing, // Total Closing
       
        symbol + " " + lineTotals.totalValue, // Total Value
      ]).eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" }  };
        cell.alignment = { horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF1d1da7" }, // Dark blue
        };
        cell.border = { bottom: { style: "thin" } };
      });
    } else {
      sheet.addRow(["No Data Available"]).mergeCells("A5:H5");
    }
  
    // Auto-adjust column widths
    sheet?.columns.forEach((column) => {
      column.width = 27;
    });
  
    // Save as Excel file
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Stock_Move_Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  

  useEffect(() => {
    StockMoveSingleAPIcall(
      {
        fromDate: FromDate===''?'':`${FromDate} 00:00:00`,
        endDate: ToDate===''?'':`${ToDate} 23:59:59`,
        branchId: branch,
        location: location,
        productId: Stock_id
      }
    )
  }, [FromDate, ToDate, branch, location, Stock_id])

  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div style={{display:"flex",justifyContent:"space-between"}}>
          <div style={{display:"flex"}}>
          <div className="sharp-arrow" style={{alignItems:"center" }} onClick={handleback}> <p>Stock Move Report&gt;</p></div>
          <div className="sharp-arrow" style={{ width: "auto",alignItems:"center" }}> <p>{vendor}</p></div>
         </div>
          <div style={{paddingInline:"15px",marginTop:"-13px"}}>
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
        </div>
        {/* <h4 style={{ margin: "32px 0px 4px 0px" }}>Stock Move</h4> */}
        <div className='global-report-table-container' style={{ maxHeight: "80vh" }}>
          <table id='table_data'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Particular</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                {/* <th>Unit Cost</th> */}
                <th>Value</th>
              </tr>
              
            </thead>
            <tbody>
              {
              tableLoading?
                [...Array(6)].map((_,i)=>(
                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                ))
              :singleView.length!==0?
              <>
                  <tr style={{ height: "38px" }}>
                    <td style={{ backgroundColor: "#e7e7e7" }}>&nbsp;</td>
                    <td style={{ backgroundColor: "#e7e7e7" ,fontSize:"1rem"}}>Opening Balance</td>
                    <td style={{ backgroundColor: "#e7e7e7" }}>{singleView[0]?.openingBalance?.toFixed(decimalPositionStock)}</td>
                    <td style={{ backgroundColor: "#e7e7e7" }}>{0?.toFixed(decimalPositionStock)}</td>
                    <td style={{ backgroundColor: "#e7e7e7" }}>{singleView[0]?.openingBalance?.toFixed(decimalPosition)}</td>
                    {/* <td style={{ backgroundColor: "#e7e7e7" }}>&nbsp;</td> */}
                    <td style={{ backgroundColor: "#e7e7e7" }}>{symbol}&nbsp;{isNaN((Number(unitCost) * Number(singleView[0]?.openingBalance?.toFixed(decimalPositionStock)))?.toFixed(decimalPosition))?(0.00):(Number(unitCost) * Number(singleView[0]?.openingBalance?.toFixed(decimalPositionStock)))?.toFixed(decimalPosition)}</td>
                  </tr>
              {singleView[0]?.stockMoves!==undefined ? singleView[0]?.stockMoves?.map((item, index) => (
                
                <tr style={{ height: "38px" }} key={index}>
                   <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.convertedDate}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.reference}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.inQuantity?.toFixed(decimalPositionStock)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.outQuantity?.toFixed(decimalPositionStock)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                  {/* <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{symbol}&nbsp;{item.unitCost?.toFixed(decimalPosition)}</td> */}
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{symbol}&nbsp;{item.value?.toFixed(decimalPosition)}</td>
                </tr>
              )):(
                <tr>
                  <td colSpan={6}>NO DATA</td>
                </tr>
              )}
              </>
              :
              <tr>
                <td colSpan={6}>No Records</td>
              </tr>
            }
            </tbody>
           {
           
           singleView.length!==0 &&
           <tfoot>
              <tr>
                <th colSpan={2}>Total</th>
                <th>{lineTotals?.totalIn}</th>
                <th>{lineTotals?.totalOut}</th>
                <th>{lineTotals?.totalClosing}</th>
                {/* <th>{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.closingBalance, 0)?.toFixed(decimalPosition)}</th> */}
                {/* <th>{symbol}&nbsp;{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.unitCost, 0)?.toFixed(decimalPosition)}</th> */}
                <th>{symbol}&nbsp;{lineTotals?.totalValue}</th>
              </tr>
            </tfoot>}
          </table>

        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <Template data={data} formValues={formValues} lineTotals={lineTotals}/>
        </div>
      </div>
    </div>

  )
}
