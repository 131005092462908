import { createSlice } from "@reduxjs/toolkit";

export const allActiveEmployeeSlice=createSlice({
    name:'allActiveEmployeeSlice',
    initialState:{
        value:undefined,
        employeeList:[]
    },
    reducers:{
        update_all_active_employee_list:(state,action)=>{
            state.value=action.payload.activeEmployees
        },
        get_employee_by_branch:(state,action)=>{
            state.employeeList=action.payload
        }
    }
})

export const {update_all_active_employee_list,get_employee_by_branch}=allActiveEmployeeSlice.actions
export default allActiveEmployeeSlice.reducer